import { Component, Input } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { Observable, of, zip } from "rxjs";
import { catchError, finalize, flatMap } from "rxjs/operators";
import { IConnectuser, IPoll, IPolloption, IPollvote } from "../../models/api";
import { ApiService } from "../../services/ApiService";
import { AuthState } from "../../state/AuthState";

@Component({
    selector: "app-poll",
    styleUrls: ["./poll.component.scss"],
    templateUrl: "./poll.component.html"
})
export class PollComponent {

    private readonly _apiService: ApiService;
    private readonly _authState: AuthState;
    private readonly _notificationService: NotificationsService;

    private _failurePollVotes: boolean;
    private _failureVote: boolean;
    private _loadingPollVotes: boolean;
    private _loadingVote: boolean;
    private _poll: IPoll;
    private _selectedOption: IPolloption;

    public constructor(
        apiService: ApiService,
        authState: AuthState,
        notificationService: NotificationsService
    ) {
        this._apiService = apiService;
        this._authState = authState;
        this._notificationService = notificationService;

        this._failurePollVotes = false;
        this._failureVote = false;
        this._loadingPollVotes = false;
        this._loadingVote = false;
        this._poll = null;
        this._selectedOption = null;
    }

    public get failurePollVotes(): boolean {
        return this._failurePollVotes;
    }

    public get failurePollVotesMessage(): string {
        return "Could not retrieve poll votes.";
    }

    public get failureVote(): boolean {
        return this._failureVote;
    }

    public get failureVoteMessage(): string {
        return "Could not vote on poll.";
    }

    public get loadingPollVotes(): boolean {
        return this._loadingPollVotes;
    }

    public get loadingVote(): boolean {
        return this._loadingVote;
    }

    public get poll(): IPoll {
        return this._poll;
    }

    @Input()
    public set poll(v: IPoll) {
        this._poll = v;
        if (v) {
            this.getPollVotes();
        }
    }

    public get hasVoted(): boolean {
        if (this._poll && this._poll.polloptions) {
            return this._poll.polloptions
                .findIndex(po => po.pollvotes && po.pollvotes
                    .findIndex(pv => pv.connectuser.email === this._authState.userEmail
                    ) > -1
                ) >-1;
        }

        return false;
    }

    public get selectedPollOption(): IPolloption {
        return this._selectedOption;
    }

    public set selectedPollOption(pollOption: IPolloption) {
        this._selectedOption = pollOption;
    }

    public get totalVotes(): number {
        if (this._poll && this._poll.polloptions) {
            let total: number = 0;
            for (const pollOption of this._poll.polloptions) {
                if (pollOption.pollvotes) {
                    total += pollOption.pollvotes.length;
                }
            }

            return total;
        }

        return 0;
    }

    public numberVotesPerOption(pollOption: IPolloption): number {
        return pollOption
            && pollOption.pollvotes
            && pollOption.pollvotes.length
            || 0;
    }

    public percentageVotes(pollOption: IPolloption): number {
        return Math.floor((this.numberVotesPerOption(pollOption) / this.totalVotes) * 100);
    }

    public vote(pollOption: IPolloption): void {
        this._loadingVote = true;
        this._failureVote = false;

        this._apiService.find<IConnectuser>("connectusers", {
            _limit: 1,
            email: this._authState.userEmail
        })
        .pipe(
            flatMap((result: IConnectuser[]) => {
                if (result && result.length) {
                    const pollVote = {
                        connectuser: { _id: result[0].id },
                        polloption: { _id: pollOption.id }
                    };

                    return this._apiService.create("pollvotes", pollVote);
                }
                else {
                    // create the user
                    const connectUser = {
                        email: this._authState.userEmail,
                        fullName: this._authState.userFullName,
                        shortName: this._authState.userFullName
                    };

                    return this._apiService.create("connectusers", connectUser);
                }
            }),
            flatMap(result => {
                if (!result) {
                    return of(null);
                }
                if ((result as IConnectuser).email) {
                    const pollVote = {
                        connectuser: { _id: (result as IConnectuser).id },
                        polloption: { _id: pollOption.id }
                    };

                    return this._apiService.create("pollvotes", pollVote);
                }
                else {
                    return of(result);
                }
            }),
            catchError(error => {
                this._notificationService.error("Could not add poll vote", error);
                // TODO: angular2-notifications does not support Angular 8 right now.
                // Remove this when it does.
                UIkit.notification(error, { status: "danger" });
                this._failureVote = true;

                return of<IPollvote>(null);
            }),
            finalize(() => {
                this._loadingVote = false;
            })
        )
        .subscribe((result: IPollvote) => {
            if (result) {
                pollOption.pollvotes.push(result);
            }
        });
    }

    private getPollVotes(): void {
        this._loadingPollVotes = true;
        this._failurePollVotes = false;

        const obs: Observable<IPollvote[]>[] = [];

        for(const pollOption of this._poll.polloptions) {
            obs.push(
                this._apiService.find<IPollvote>("pollvotes", {
                    "polloption._id": pollOption.id
                })
            );
        }

        zip(...obs)
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not get poll votes", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failurePollVotes = true;

                    return of<IPollvote[][]>([]);
                }),
                finalize(() => {
                    this._loadingPollVotes = false;
                })
            )
            .subscribe(result => {
                if (result && result.length) {
                    for (const pollVoteCollection of result) {
                        for (const pollVote of pollVoteCollection) {
                            const pollOptionIndex = this._poll.polloptions.findIndex(po => po.id === pollVote.polloption.id);
                            if (pollOptionIndex > -1) {
                                if (!this._poll.polloptions[pollOptionIndex].pollvotes) {
                                    this._poll.polloptions[pollOptionIndex].pollvotes = [];
                                }
                                this._poll.polloptions[pollOptionIndex].pollvotes.push(pollVote);
                            }
                        }
                    }
                }
            });
    }

}
