import { SafeUrl } from "@angular/platform-browser";
import { ILayoutJson } from "../api/layoutJson";
import { IVideo } from "../api/video";

// TODO: This will need to implement IWidgetBase.
// May as well wait until the new grid system is implemented.
export class Video implements IVideo {
    public _id: string;
    public Featured: boolean;
    public id: string;
    public media?: Blob;
    public layoutJson: ILayoutJson;
    public poster?: Blob;
    public safeUrl: SafeUrl;
    public title: string;
    public url?: string;

    public constructor(video: IVideo) {
        this._id = video._id;
        this.Featured = video.Featured;
        this.id = video.id;
        this.media = video.media;
        this.poster = video.poster;
        this.safeUrl = video.safeUrl;
        this.title = video.title;
        this.url = video.url;
    }
}
