import { IRichtextwidget, IWidgetbase } from "../api";
import { ILayoutJson } from "../api/layoutJson";

export class RichTextWidget implements IWidgetbase {
    public _id: string;
    public content: string;
    public layoutJson: ILayoutJson;

    public constructor(richTextWidget: IRichtextwidget) {
        this._id = richTextWidget._id;
        this.content = richTextWidget.content;
    }
}
