import { Component, Input } from "@angular/core";
import { splitRoute } from "../../../helpers/data.helpers";
import { IPage } from "../../../models/api";
import { CommonUtils } from "../../common/commonUtils";

@Component({
    selector: "app-featured-post-summary",
    styleUrls: ["./featured-post-summary.component.scss"],
    templateUrl: "./featured-post-summary.component.html"
})
export class FeaturedPostSummaryComponent {
    private _featured: boolean;
    private _imageHeight: string;
    private _imageWidth: string;
    private _page: IPage;

    public constructor() {
        this._featured = false;
        this._page = null;
        this._imageWidth = "100%";
        this._imageHeight = "75%";
    }

    public get featured(): boolean {
        return this._featured;
    }

    @Input()
    public set featured(value: boolean) {
        this._featured = value;
    }

    public get imageHeight(): string {
        return this._imageHeight;
    }

    @Input()
    public set imageHeight(v: string) {
        if (v) {
            this._imageHeight = v;
        }
    }

    public get imageWidth(): string {
        return this._imageWidth;
    }

    @Input()
    public set imageWidth(v: string) {
        if (v) {
            this._imageWidth = v;
        }
    }

    public get page(): IPage {
        return this._page;
    }

    @Input()
    public set page(value: IPage) {
        this._page = value;
    }

    public get previewImage(): string {
        return this._page
        && this._page.previewImage
        && CommonUtils.getImageUrl(this._page.previewImage)
        || "";
    }

    public get routerLink(): string[] {
        return splitRoute(this._page.url);
    }

    public getStyle(): Object {
        return {
            height: this._imageHeight,
            width: this._imageWidth
        };
    }
}
