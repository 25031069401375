import { GraphQLParams } from "../types/GraphQLParams";
import { QueryParams } from "../types/QueryParams";

export const pagesQuery = (includeAll: boolean = false, graphQLParams: GraphQLParams = {}) => `
    query($where: JSON) {
        pages(
            where: $where
    ${
    graphQLParams.limit > 0
        ? `
            ,
            limit: ${graphQLParams.limit}
        `
        : ""
    }
    ${
    graphQLParams.skip > 0
        ? `
            ,
            skip: ${graphQLParams.skip}
        `
        : ""
    }
    ${
    graphQLParams.sort
        ? `
            ,
            sort: "${graphQLParams.sort}"
            `
        : ""
    }
        ) {
            _id,
            createdAt,
            featuredUntil,
            previewImage {
                url
            },
            previewText,
            isPost,
            tags {
                _id,
                name
            },
            title,
            updatedAt,
            url${
    includeAll
        ? `
            ,
            comments {
                _id
            },
            imagegallerywidgets {
                _id,
                imagegallerywidgetitems {
                    _id,
                    content,
                    image {
                        _id
                    },
                    order
                }
            },
            imagewidgets {
                _id,
                image {
                    _id
                }
            },
            likes {
                _id,
                connectuser {
                    _id,
                    email,
                    fullName,
                    shortName
                },
                createdAt,
                updatedAt
            },
            legacyhtmlwidgets {
                _id,
                html
            },
            richtextwidgets {
                _id,
                content,
            }
        `
        : ""
    }
        }
    }
`;

export const pagesQueryParams = (categoryIds: string[], isPost: boolean = false, additionalParams: QueryParams = {}) => {
    return <QueryParams>{
        categories_in: categoryIds,
        isPost,
        ...additionalParams
    };
};

/**
 * Gets the remainder of pages to fill the gap if fewer featured pages were found than the desired limit.
 * @param categoryIds IDs of categories on which to filter pages.
 * @param pageIds IDs of pages to filter out because they have already been accounted for.
 * @param isPost Whether or not the targete results are Posts as opposed to plain Pages. Defaults to false.
 * @param additionalParams Optional additional conditions on which to query.
 */
export const remainderPagesQueryParams = (
    categoryIds: string[],
    pageIds: string[],
    isPost: boolean = false,
    additionalParams: QueryParams = {}
) => {
    return <QueryParams>{
        _id_nin: pageIds,
        categories_in: categoryIds,
        isPost,
        ...additionalParams
    };
};
