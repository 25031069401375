import { Component } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { sortAlpha } from "../../helpers/data.helpers";
import { ITag } from "../../models/api";
import { ApiService } from "../../services/ApiService";

@Component({
    providers: [ApiService],
    selector: "app-tags",
    styleUrls: ["./tags.component.scss"],
    templateUrl: "./tags.component.html"
})
export class TagsComponent {
    private readonly _apiService: ApiService;
    private readonly _notificationService: NotificationsService;

    private _failureTags: boolean;
    private _filteredTags: ITag[];
    private _loadingTags: boolean;
    private _tagFilter: string;
    private _tags: ITag[];

    public constructor(
        apiService: ApiService,
        notificationService: NotificationsService
    ) {
        this._apiService = apiService;
        this._notificationService = notificationService;

        this._failureTags = false;
        this._filteredTags = [];
        this._loadingTags = false;
        this._tagFilter = "";
        this._tags = [];

        this.getTags();
    }

    public get failureTags(): boolean {
        return this._failureTags;
    }

    public get failureTagsMessage(): string {
        return "Could not get tags.";
    }

    public get filteredTags(): ITag[] {
        return this._filteredTags;
    }

    public get loadingTags(): boolean {
        return this._loadingTags;
    }

    public get tagFilter(): string {
        return this._tagFilter;
    }

    public set tagFilter(value: string) {
        this._tagFilter = value;
    }

    public filterTags(): void {
        this._filteredTags = this._tags.filter(t =>
            !this._tagFilter
            || t.name
                .toLowerCase()
                .replace(/\s/g, "")
                .includes(
                    this._tagFilter
                        .toLowerCase()
                        .replace(/\s/g, "")
                )
        );
    }

    public resetFilter(): void {
        this._tagFilter = "";
        this._filteredTags = this._tags;
    }

    private getTags(): void {
        this._failureTags = false;
        this._loadingTags = true;
        this._filteredTags = [];
        this._tags = [];

        this._apiService
            .getMany<ITag>("tags")
            .pipe(
                catchError(error => {
                    this._failureTags = true;
                    this._notificationService.error("Could not get tags", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<ITag[]>([]);
                }),
                finalize(() => {
                    this._loadingTags = false;
                })
            )
            .subscribe(tags => {
                this._tags = sortAlpha(tags, t => t.name);
                this._filteredTags = this._tags;
            });
    }
}
