import { Component, ContentChild, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { Select2OptionData } from "ng2-select2";
import * as pluralize from "pluralize";
// tslint:disable-next-line: no-duplicate-imports
import { singular } from "pluralize";
import { of, Subject } from "rxjs";
import { catchError, finalize, flatMap, takeUntil } from "rxjs/operators";
import { augmentQueryParams } from "../../../helpers/data.helpers";
import { IPage } from "../../../models/api/page";
import { ITag } from "../../../models/api/tag";
import { ApiService } from "../../../services/ApiService";
import { ViewTypeState } from "../../../state/ViewTypeState";
import { ContentTypeComponent } from "../../../types/ContentTypeComponent";
import { QueryParams } from "../../../types/QueryParams";

@Component({
    providers: [ApiService],
    selector: "app-search",
    styleUrls: ["./search.component.scss"],
    templateUrl: "./search.component.html"
})
export class SearchComponent<T> implements OnInit, OnDestroy {
    private readonly LIMIT: number = 10;

    @ViewChild("defaultTemplate", { static: true })
    private readonly _defaultTemplate: TemplateRef<T>;

    @ContentChild(TemplateRef, { static: true })
    private readonly _searchResultTemplate: TemplateRef<T>;

    private readonly _apiService: ApiService;
    private readonly _destroyed: Subject<void>;
    private readonly _notificationService: NotificationsService;
    private readonly _selectOptions: Select2Options;

    private _allowSearch: boolean;
    private _categoryId: string;
    private _contentTypeName: ContentTypeComponent;
    private _currentResultPage: number;
    private _failureResults: boolean;
    private _initLoad: boolean;
    private _loadingResults: boolean;
    private _loadingTags: boolean;
    private _results: T[];
    private _searchFilterCategory: string;
    private _searchFilterDirection: string;
    private _searchString: string;
    private _selectData: Select2OptionData[];
    private _selectValue: string;
    private _start: number;
    private _tagId: string;
    private _total: number;
    private _viewTypeCategoryIds: string[];

    public constructor(
        apiService: ApiService,
        notificationService: NotificationsService,
        route: ActivatedRoute,
        viewTypeState: ViewTypeState
    ) {
        this._apiService = apiService;
        this._destroyed = new Subject<void>();
        this._notificationService = notificationService;
        this._selectOptions = {};

        this._allowSearch = true;
        this._categoryId = "";
        this._contentTypeName = null;
        this._failureResults = false;
        this._initLoad = true;
        this._loadingResults = false;
        this._loadingTags = false;
        this._results = [];
        this._searchFilterCategory = "createdDate";
        this._searchFilterDirection = "desc";
        this._searchString = "";
        this._selectData = [];
        this._selectValue = "-";
        this._start = 0;
        this._tagId = "";
        this._total = 0;
        this._viewTypeCategoryIds = [];

        route
            .url
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(urlSegment => {
                if (urlSegment.findIndex(us => us.path === "moreNews") > -1) {
                    this._contentTypeName = "pages";
                    this.search();
                }
            });

        route
            .params
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(params => {
                const tagId = params.tagId;
                const categoryId = params.categoryId;

                if (tagId || categoryId) {
                    this._tagId = tagId;
                    this._contentTypeName = "tags";
                    this._allowSearch = false;
                }

                if (categoryId) {
                    this._categoryId = categoryId;
                    this._contentTypeName = "categories";
                    this._allowSearch = false;
                }
                if (tagId || categoryId) {
                    this.search();
                }
            });

        viewTypeState
            .viewTypeActions
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(vt => {
                if (vt) {
                    this._viewTypeCategoryIds = vt.categories.map(c => c.id);
                }
            });
    }

    public ngOnInit(): void {
        // Go ahead and search everything on page load to avoid "No results found" message.
        // this.search();
        this.getTags();
    }

    public ngOnDestroy(): void {
        this._destroyed.next(null);
        this._destroyed.complete();
    }

    public get allowSearch(): boolean {
        return this._allowSearch;
    }

    public get contentTypeName(): ContentTypeComponent {
        return this._contentTypeName;
    }

    @Input()
    public set contentTypeName(value: ContentTypeComponent) {
        this._contentTypeName = value;
    }

    public get contentTypeNameHeader(): string {
        if (!this._contentTypeName) {
            return "";
        }

        // tslint:disable-next-line: newline-per-chained-call
        return `${this._contentTypeName.substr(0, 1).toUpperCase()}${this._contentTypeName.substr(1)}`;
    }

    public get contentTypeNameSearchHint(): string {
        let searchHint = "Search";

        if (this._contentTypeName) {
            searchHint += ` ${this._contentTypeName}...`;
        }

        return searchHint;
    }

    public get failureResults(): boolean {
        return this._failureResults;
    }

    public get failureResultsMessage(): string {
        return `Could not get ${this._contentTypeName}.`;
    }

    public get initLoad(): boolean {
        return this._initLoad;
    }

    public get loadingResults(): boolean {
        return this._loadingResults;
    }

    public get loadingTags(): boolean {
        return this._loadingTags;
    }

    public get results(): T[] {
        if (this._tagId || this._categoryId) {
            const filtered = this._results.filter((r: T, index: number) => {
                return index >= this._start && index <= ((this._start + 1) * this.LIMIT);
            });

            return filtered;
        }

        return this._results;
    }

    public get searchFilterCategory(): string {
        return this._searchFilterCategory;
    }

    public set searchFilterCategory(v: string) {
        this._searchFilterCategory = v;
    }

    public get searchFilterDirection(): string {
        return this._searchFilterDirection;
    }

    public set searchFilterDirection(v: string) {
        this._searchFilterDirection = v;
    }

    public get searchResultTemplate(): TemplateRef<T> {
        return this._searchResultTemplate || this._defaultTemplate;
    }

    public get selectData(): Select2OptionData[] {
        return this._selectData;
    }

    public get selectOptions(): Select2Options {
        return this._selectOptions;
    }

    public get selectValue(): string {
        return this._selectValue;
    }

    public set selectValue(v: string) {
        if (v && v.length) {
            this._selectValue = v;
        }
    }

    public get resultsLimit(): number {
        return this.LIMIT;
    }

    public get totalResults(): number {
        return this._total;
    }

    public get currentResultPage(): number {
        return this._currentResultPage;
    }

    public set currentResultPage(v: number) {
        this._currentResultPage = v;
    }

    public pageResults(pageNumber: number): void {
        this._start = (pageNumber - 1) * this.LIMIT;
        this._currentResultPage = pageNumber;
        this.search(this._searchString, true);
    }

    public search(value: string = null, paging: boolean = false): void {
        this._initLoad = false;
        if (!paging) {
            this._start = 0;
            this._results = [];
        }

        this._failureResults = false;

        const errorHanlder = (error: string) => {
            this._failureResults = true;
            this._notificationService.error(`Could not get ${this._contentTypeName}`, error);
            // TODO: angular2-notifications does not support Angular 8 right now.
            // Remove this when it does.
            UIkit.notification(error, { status: "danger" });

            return of<T[]>([]);
        };

        const countParams: QueryParams = {};
        let queryParams: QueryParams = {
            _limit: this.LIMIT,
            _start: this._start
        };

        if (this._contentTypeName === "pages") {
            queryParams = augmentQueryParams(queryParams, {
                _sort: `${this._searchFilterCategory}:${this._searchFilterDirection},featuredStart:DESC`,
                categories_in: this._viewTypeCategoryIds
            });

            if (this._selectValue !== "-") {
                queryParams.tags_in = this._selectValue;
            }
        }

        if (value) {
            if (this._searchString !== value) {
                this._searchString = value;
                this._total = 0;
            }

            queryParams._q = `${pluralize(value)}|${singular(value)}`;
            countParams._q = `${pluralize(value)}|${singular(value)}`;
        }

        if (this._total === 0 && !(this._tagId || this._categoryId)) {
            this._apiService.count(this._contentTypeName, countParams)
                .subscribe(count => {
                    this._total = count;
                });
        }

        if (this._tagId || this._categoryId) {
            queryParams._id = this._tagId || this._categoryId;

            if (this._results && this._results.length) {
                return;
            }

            this._loadingResults = true;

            this._apiService.find<T>(this._contentTypeName, queryParams)
                .pipe(
                    catchError(errorHanlder),
                    finalize(() => {
                        this._loadingResults = false;
                    })
                )
                .subscribe((results: any[]) => {
                    if (results && results.length) {
                        this._total = results[0].pages.length;
                        this._results = results[0].pages;
                    }
                });

        }
        else {

            this._loadingResults = true;

            this._apiService.find<T>(this._contentTypeName, queryParams)
                .pipe(
                    flatMap(results => {
                        this._results = results;
                        if (!paging && this._searchString) {
                            const tagQueryParams = queryParams = augmentQueryParams({
                                _q: this._searchString
                            }, {
                               categories_in: this._viewTypeCategoryIds
                            });

                            return this._apiService.getMany<IPage>("pages", tagQueryParams);
                        }

                        return of<IPage[]>([]);
                    }),
                    catchError((error: string) => {
                        this._failureResults = true;
                        this._notificationService.error(`Could not get ${this._contentTypeName}`, error);
                        // TODO: angular2-notifications does not support Angular 8 right now.
                        // Remove this when it does.
                        UIkit.notification(error, { status: "danger" });

                        return of<IPage[]>([]);
                    }),
                    finalize(() => {
                        this._loadingResults = false;
                    })
                )
                .subscribe(results => {
                    if (results && results.length) {
                        const tags: ITag[] = [];
                        for (const result of results) {
                            tags.push(...result.tags);
                        }

                        const distinctTags = [... new Set(tags.map(t => t.id))];

                        this._selectData = this._selectData.filter(sd => distinctTags.findIndex(t => t === sd.id) >= 0);
                        this._selectData.unshift({
                            id: "-",
                            text: "All Tags"
                        });
                    }
                });
        }
    }

    private getTags(): void {
        if (this._contentTypeName === "pages") {
            const queryParams = augmentQueryParams({}, {
                categories_in: this._viewTypeCategoryIds
            });

            this._loadingTags = true;
            this._apiService.getMany<IPage>("pages", queryParams)
                .pipe(
                    finalize(() => {
                        this._loadingTags = false;
                    })
                )
                .subscribe(results => {
                    if (results && results.length) {
                        this._selectData = [];
                        const tags: ITag[] = [];
                        for (const result of results) {
                            tags.push(...result.tags);
                        }

                        const distinct: { id: string, name: string }[] = [];
                        const map = new Map();
                        for (const item of tags) {
                            if(!map.has(item.id)){
                                map.set(item.id, true);    // set any value to Map
                                distinct.push({
                                    id: item.id,
                                    name: item.name
                                });
                            }
                        }

                        for (const tag of distinct) {
                            this._selectData.push({
                                id: tag.id,
                                text: tag.name
                            });
                        }

                        this._selectData.unshift({
                            id: "-",
                            text: "All Tags"
                        });
                    }
                });
        }
    }
}
