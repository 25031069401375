import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormioAppConfig, FormioModule } from "angular-formio";
import { SimpleNotificationsModule } from "angular2-notifications";
import { Select2Module } from "ng2-select2";
import { MarkdownModule } from "ngx-markdown";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxPrintModule } from "ngx-print";
import { SimplemdeModule } from "ngx-simplemde";
import { CountDown } from "../npm-workarounds/ng2-date-countdown/countdown";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommentComponent } from "./components/common/comment/comment.component";
import { SearchComponent } from "./components/common/search/search.component";
import { FavoritesComponent } from "./components/favorites/favorites.component";
import { JsonFormComponent } from "./components/forms/json-form/json-form.component";
import { MenuMainBottomComponent } from "./components/global/menu-main-bottom/menu-main-bottom.component";
import { MenuMainTopComponent } from "./components/global/menu-main-top/menu-main-top.component";
import { MenuSocialComponent } from "./components/global/menu-social/menu-social.component";
import { MenuTopComponent } from "./components/global/menu-top/menu-top.component";
import { BlogLinksListComponent } from "./components/home/blog-links-list/blog-links-list.component";
import { FeaturedPageSummaryComponent } from "./components/home/featured-page-summary/featured-page-summary.component";
import { FeaturedPostSummaryComponent } from "./components/home/featured-post-summary/featured-post-summary.component";
import { HomeComponent } from "./components/home/home.component";
import { ImageGallaryComponent } from "./components/image-gallary/image-gallary.component";
import { LeftFocusItemComponent } from "./components/left-focus-item/left-focus-item.component";
import { MarqueeComponent } from "./components/marquee/marquee.component";
import { AccordionComponent } from "./components/page/accordion/accordion.component";
import { ImageWidgetComponent } from "./components/page/image-widget/image-widget.component";
import { LegacyHtmlWidgetComponent } from "./components/page/legacy-html-widget/legacy-html-widget.component";
import { PageComponent } from "./components/page/page.component";
import { RichTextWidgetComponent } from "./components/page/rich-text-widget/rich-text-widget.component";
import { PagesComponent } from "./components/pages/pages.component";
import { PollComponent } from "./components/poll/poll.component";
import { PostsComponent } from "./components/posts/posts.component";
import { ShareStoryComponent } from "./components/share-story/share-story.component";
import { TagComponent } from "./components/tag/tag.component";
import { TagsComponent } from "./components/tags/tags.component";
import { VideoComponent } from "./components/video/video.component";
import { FormioConfig } from "./constants/formio-config";
import { ModalDirective } from "./directives/modal.directive";
import { SanitizedHtml } from "./pipes/sanitizedHtml";
import { AuthState } from "./state/AuthState";
import { ViewTypeState } from "./state/ViewTypeState";
import { ViewNavBarComponent } from './components/global/view-nav-bar/view-nav-bar.component';
import { RestInterceptor } from "./services/utility/RestInterceptor"
import { environment } from '../environments/environment'
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AccordionComponent,
        AppComponent,
        BlogLinksListComponent,
        CommentComponent,
        CountDown,
        FavoritesComponent,
        FeaturedPageSummaryComponent,
        FeaturedPostSummaryComponent,
        HomeComponent,
        ImageGallaryComponent,
        ImageWidgetComponent,
        JsonFormComponent,
        LeftFocusItemComponent,
        LegacyHtmlWidgetComponent,
        MarqueeComponent,
        MenuMainBottomComponent,
        MenuMainTopComponent,
        MenuSocialComponent,
        MenuTopComponent,
        ModalDirective,
        PageComponent,
        PagesComponent,
        PollComponent,
        PostsComponent,
        RichTextWidgetComponent,
        SanitizedHtml,
        SearchComponent,
        ShareStoryComponent,
        TagComponent,
        TagsComponent,
        VideoComponent,
        ViewNavBarComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FlexLayoutModule,
        FormioModule,
        FormsModule,
        HttpClientModule,
        MarkdownModule.forRoot(),       
        NgxPaginationModule,
        NgxPrintModule,
        OktaAuthModule,
        Select2Module,
        SimpleNotificationsModule.forRoot(),
        SimplemdeModule.forRoot({
            options: {
                autosave: {
                    enabled: true, uniqueId: "SvmConnectId"
                }
            }
        }),
    ],
    providers: [
        AuthState,
        ViewTypeState,
        ViewTypeState,
        {
            provide: FormioAppConfig,
            useValue: FormioConfig
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RestInterceptor,
            multi: true
        },
        {
            provide: OKTA_CONFIG,
            useValue: environment.oktaConfig
        },
    ]
})
export class AppModule {
}