import { Component, Input } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of, throwError } from "rxjs";
import { catchError, finalize, flatMap, map } from "rxjs/operators";
import { splitRoute } from "../../../helpers/data.helpers";
import { IComment, IConnectuser } from "../../../models/api";
import { commentsQuery, repliesQueryParams } from "../../../queries/comment.queries";
import { ApiService } from "../../../services/ApiService";
import { AuthState } from "../../../state/AuthState";

@Component({
    providers: [ApiService],
    selector: "app-comment",
    styleUrls: ["./comment.component.scss"],
    templateUrl: "./comment.component.html"
})
export class CommentComponent {
    private readonly _apiService: ApiService;
    private readonly _authState: AuthState;
    private readonly _notificationService: NotificationsService;

    private _allowReply: boolean;
    private _comment: IComment;
    private _failureAddReply: boolean;
    private _failureReplies: boolean;
    private _loadingReplies: boolean;
    private _newReply: IComment;
    private _replies: IComment[];
    private _savingReply: boolean;
    private _showPageLink: boolean;
    private _showReplies: boolean;
    private _showReplyForm: boolean;

    public constructor(
        apiService: ApiService,
        authState: AuthState,
        notificationService: NotificationsService
    ) {
        this._apiService = apiService;
        this._authState = authState;
        this._notificationService = notificationService;

        // TODO: Will this ever be false?
        // Need to pass in false in template replies if we only desire one level of replies.
        this._allowReply = true;
        this._comment = null;
        this._failureAddReply = false;
        this._failureReplies = false;
        this._loadingReplies = false;
        this._newReply = <IComment>{};
        this._replies = [];
        this._savingReply = false;
        this._showPageLink = false;
        this._showReplies = false;
        this._showReplyForm = false;
    }

    public get allowReply(): boolean {
        return this._allowReply;
    }

    @Input()
    public set allowReply(value: boolean) {
        this._allowReply = value;
    }

    public get comment(): IComment {
        return this._comment;
    }

    @Input()
    public set comment(value: IComment) {
        this._comment = value;
    }

    public get failureAddReply(): boolean {
        return this._failureAddReply;
    }

    public get failureAddReplyMessage(): string {
        return "Could not save reply.";
    }

    public get failureReplies(): boolean {
        return this._failureReplies;
    }

    public get failureRepliesMessage(): string {
        return "Could not get replies.";
    }

    public get loadingReplies(): boolean {
        return this._loadingReplies;
    }

    public get newReply(): IComment {
        return this._newReply;
    }

    public get replies(): IComment[] {
        return this._replies;
    }

    public get routerLink(): string[] {
        return splitRoute(this._comment.page && this._comment.page.url || "");
    }

    public get savingReply(): boolean {
        return this._savingReply;
    }

    public get showPageLink(): boolean {
        return this._showPageLink;
    }

    @Input()
    public set showPageLink(value: boolean) {
        this._showPageLink = value;
    }

    public get showReplies(): boolean {
        return this._showReplies;
    }

    public get showRepliesLabel(): string {
        return `${this._showReplies ? "Hide" : "Show"} replies`;
    }

    public get showReplyForm(): boolean {
        return this._showReplyForm;
    }

    public get showReplyFormLabel(): string {
        const name = this._comment.connectuser && this._comment.connectuser.fullName || "?";

        return `Reply to ${name}`;
    }

    public addReply(): void {
        if (!this._newReply.text) {
            this._notificationService.warn("Text is required to add a reply.");
            // TODO: angular2-notifications does not support Angular 8 right now.
            // Remove this when it does.
            UIkit.notification("Text is required to add a reply.", { status: "warning" });

            return;
        }

        this._failureAddReply = false;
        this._savingReply = true;

        this._apiService
            .find<IConnectuser>("connectusers", {
                _limit: 1,
                email: this._authState.userEmail
            })
            .pipe(
                flatMap(existingConnectUserResult => {
                    if (!(existingConnectUserResult && existingConnectUserResult.length)) {
                        const newConnectUser = <IConnectuser>{
                            email: this._authState.userEmail,
                            fullName: this._authState.userFullName,
                            shortName: this._authState.userFirstName
                        };

                        return this._apiService.create<IConnectuser>("connectusers", newConnectUser);
                    }

                    return of(existingConnectUserResult[0]);
                }),
                flatMap(connectUser => {
                    if (!connectUser) {
                        return throwError("Could not create user information.");
                    }

                    const reply = <IComment>{
                        connectuser: {
                            _id: connectUser.id
                        },
                        parentComment: {
                            _id: this._comment._id
                        },
                        text: this._newReply.text
                    };

                    return this._apiService.create<IComment>("comments", reply);
                }),
                catchError(error => {
                    this._failureAddReply = true;
                    this._notificationService.error("Could not add reply.", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<IComment>(null);
                }),
                finalize(() => {
                    this._savingReply = false;
                })
            )
            .subscribe(reply => {
                if (!reply) {
                    this._notificationService.error("Could not save reply.");
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification("Could not save reply.", { status: "danger" });

                    return;
                }

                this._notificationService.success("Reply saved.");
                // TODO: angular2-notifications does not support Angular 8 right now.
                // Remove this when it does.
                UIkit.notification("Reply saved.", { status: "success" });

                // Add to array of loaded replies for display.
                this._replies.push(reply);

                // Add to array of replies on _comment to enable display if no replies previously existed.
                if (!this._comment.replies) {
                    this._comment.replies = [];
                }

                this._comment.replies.push(reply);

                // In case there were initially no replies to show, set showReplies to true.
                this._showReplies = true;

                // Reset and hide reply form.
                this.cancelAddReply();
            });
    }

    public cancelAddReply(): void {
        this._showReplyForm = false;
        this._newReply = <IComment>{};
    }

    public toggleShowReplies(): void {
        // If no replies have been loaded, get replies for the first time.
        if (!this._replies.length) {
            this.getReplies();

            return;
        }

        this._showReplies = !this._showReplies;
    }

    public toggleShowReplyForm(): void {
        this._showReplyForm = !this._showReplyForm;
    }

    private getReplies(): void {
        this._replies = [];
        this._failureReplies = false;
        this._loadingReplies = true;

        this._apiService
            .query<IComment>(commentsQuery(), repliesQueryParams(this._comment._id))
            .pipe(
                map(response => {
                    return response.data && response.data.comments || [];
                }),
                catchError(error => {
                    this._notificationService.error("Could not get replies", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failureReplies = true;

                    return of<IComment[]>([]);
                }),
                finalize(() => {
                    this._loadingReplies = false;
                })
            )
            .subscribe(replies => {
                this._replies = replies;

                if (this._replies.length) {
                    this._showReplies = true;
                }
            });
    }
}
