import { Component, Input } from "@angular/core";
import { RichTextWidget } from "../../../models/widgets/RichTextWidget";

@Component({
    selector: "app-rich-text-widget",
    styleUrls: ["./rich-text-widget.component.scss"],
    templateUrl: "./rich-text-widget.component.html"
})
export class RichTextWidgetComponent {
    private _richTextWidget: RichTextWidget;

    public constructor() {
        this._richTextWidget = null;
    }

    public get richTextWidget(): RichTextWidget {
        return this._richTextWidget;
    }

    @Input()
    public set richTextWidget(value: RichTextWidget) {
        this._richTextWidget = value;
    }
}
