import { Component, ViewChild, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { of } from "rxjs";
import { catchError, filter, finalize, map } from "rxjs/operators";
import { MenuMainTopComponent } from "./components/global/menu-main-top/menu-main-top.component";
import { MenuTopComponent } from "./components/global/menu-top/menu-top.component";
import { browserIsIE } from "./helpers/compatibility.helpers";
import { sortOrderables } from "./helpers/data.helpers";
import { CommonUI, defaultLogoSvg } from "./helpers/view.helpers";
import { IMainmenuitem } from "./models/api";
import { mainmenuitemsQuery } from "./queries/mainmenuitem.queries";
import { ApiService } from "./services/ApiService";
import { AuthState } from "./state/AuthState";
import { ViewTypeState } from "./state/ViewTypeState";
import { environment } from 'src/environments/environment';
import { OktaAuthService } from "@okta/okta-angular";

@Component({
    providers: [ApiService],
    selector: "app-root",
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
    @ViewChild("menuMainTop", { static: false })
    private readonly _menuMain: MenuMainTopComponent;
    @ViewChild(MenuTopComponent, { static: false })
    private readonly _menuTop: MenuTopComponent;

    private readonly _apiService: ApiService;
    private readonly _authState: AuthState;
    private readonly _oktaAuth: OktaAuthService;
    private readonly _browserIsIE: boolean;
    private readonly _notificationService: NotificationsService;

    private _failureMainMenuItems: boolean;
    private _loadingMainMenuItems: boolean;
    private _logoSvg: string;
    private _mainMenuItems: IMainmenuitem[];

    public constructor(
        apiService: ApiService,
        authState: AuthState,
        oktaAuth: OktaAuthService,
        notificationService: NotificationsService,
        router: Router,
        viewTypeState: ViewTypeState
    ) {
        this._apiService = apiService;
        this._authState = authState;
        this._oktaAuth = oktaAuth;
        this._browserIsIE = browserIsIE();
        this._notificationService = notificationService;

        this._failureMainMenuItems = false;
        this._loadingMainMenuItems = false;
        this._mainMenuItems = [];

        viewTypeState
            .viewTypeActions
            .subscribe(vt => {
                if (vt) {
                    this._logoSvg = vt.logoSvg || defaultLogoSvg;
                    CommonUI.showTerminixNavBar = vt.id === environment.terminixViewTypeId;
                }
            });

        this._oktaAuth.$authenticationState.subscribe(authenticated => {
            if (authenticated && this._mainMenuItems.length == 0) {
                this.getMainMenuItems();
            }            
        });

        // Google Analytics.
        router
            .events
            .pipe(
                filter(e => e instanceof NavigationEnd)
            )
            .subscribe(() => {
                (<any>window).gtag("config", "UA-150176446-1", {
                    page_location: window.location.href,
                    page_path: router.url,
                    page_title: "Terminix Connect"
                });
            });
    }

    public get authenticated(): boolean {
        return this._authState.isAuthenticated;
    }

    public get browserIsIE(): boolean {
        return this._browserIsIE;
    }

    public get failureMainMenuItems(): boolean {
        return this._failureMainMenuItems;
    }

    public get failureMainMenuItemsMessage(): string {
        return "Could not get main menu items.";
    }

    public get loadingMainMenuItems(): boolean {
        return this._loadingMainMenuItems;
    }

    public get logoSvg(): string {
        return this._logoSvg;
    }

    public get mainMenuItems(): IMainmenuitem[] {
        return this._mainMenuItems;
    }

    public showMenuMainOffcanvas(): void {
        this._menuMain.showOffcanvas();
    }

    public showMenuTopOffcanvas(): void {
        this._menuTop.showOffcanvas();
    }

    private getMainMenuItems(): void {
        this._mainMenuItems = [];
        this._failureMainMenuItems = false;
        this._loadingMainMenuItems = true;
        if (this._authState.isAuthenticated) {
            this._apiService
                .query<IMainmenuitem>(mainmenuitemsQuery())
                .pipe(
                    map(mainMenuItemsResponse => {
                        return mainMenuItemsResponse.data && mainMenuItemsResponse.data.mainmenuitems || [];
                    }),
                    catchError(error => {
                        this._failureMainMenuItems = true;
                        //this._notificationService.error("Could not get main menu items", error);
                        // TODO: angular2-notifications does not support Angular 8 right now.
                        // Remove this when it does.
                        //UIkit.notification(error, { status: "danger" });

                        return of<IMainmenuitem[]>([]);
                    }),
                    finalize(() => {
                        this._loadingMainMenuItems = false;
                    })
                )
                .subscribe(mainMenuItems => {
                    mainMenuItems.forEach(mmi => {
                        if (mmi.mainmenusubmenuitems) {
                            mmi.mainmenusubmenuitems = sortOrderables(mmi.mainmenusubmenuitems);
                        }
                    });

                    this._mainMenuItems = sortOrderables(mainMenuItems);
                });
        }
    }

    async ngOnInit() {
        if (!this._oktaAuth.isAuthenticated()) {
            this._oktaAuth.handleLoginRedirect();
        }    
    }
}
