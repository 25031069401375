import { Component } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of, throwError } from "rxjs";
import { catchError, finalize, flatMap } from "rxjs/operators";
import { IConnectuser } from "../../models/api";
import { ISharedstory } from "../../models/api/sharedstory";
import { ApiService } from "../../services/ApiService";
import { AuthState } from "../../state/AuthState";

@Component({
    selector: "app-share-story",
    styleUrls: ["./share-story.component.scss"],
    templateUrl: "./share-story.component.html"
})
export class ShareStoryComponent {

    private readonly _apiService: ApiService;
    private readonly _authState: AuthState;
    private readonly _notificationService: NotificationsService;

    private _content: string;
    private _failure: boolean;
    private _loading: boolean;
    private _shared: boolean;

    public constructor(apiService: ApiService,
        authState: AuthState,
        notificationService: NotificationsService
    ) {
        this._apiService = apiService;
        this._authState = authState;
        this._notificationService = notificationService;

        this._content = "";
        this._loading = false;
        this._failure = false;
        this._shared = false;
    }

    public get content(): string {
        return this._content;
    }

    public set content(v: string) {
        this._content = v;
    }

    public get failure(): boolean {
        return this._failure;
    }

    public get failureMessage(): string {
        return "Could not share story.";
    }

    public get loading(): boolean {
        return this._loading;
    }

    public get shared(): boolean {
        return this._shared;
    }

    public get sharedMessage(): string {
        return "Your story has been shared!";
    }

    public share(): void {
        this._loading = true;
        this._failure = false;

        this._apiService.find<IConnectuser>("connectusers", {
            _limit: 1,
            email: this._authState.userEmail
        })
        .pipe(
            flatMap(existingConnectUserResult => {
                if (!(existingConnectUserResult && existingConnectUserResult.length)) {
                    const newConnectUser = <IConnectuser>{
                        email: this._authState.userEmail,
                        fullName: this._authState.userFullName,
                        shortName: this._authState.userFullName
                    };

                    return this._apiService.create<IConnectuser>("connectusers", newConnectUser);
                }

                return of(existingConnectUserResult[0]);
            }),
            flatMap(connectUser => {
                if (!connectUser) {
                    return throwError("Could not create user information.");
                }

                const sharedStory = {
                    connectuser: {
                        _id: connectUser.id
                    },
                    content: this._content
                };

                return this._apiService.create<ISharedstory>("sharestories", sharedStory as any);
            }),
            catchError(error => {
                this._failure = true;
                this._notificationService.error("Could not add comment.", error);

                return of<ISharedstory>(null);
            }),
            finalize(() => {
                this._loading = false;
            })
        )
        .subscribe(sharedStory => {
            if (!sharedStory) {
                this._notificationService.error("Could not save comment.");

                return;
            }
            this._shared = true;
        });
    }

}
