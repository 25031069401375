import { Component } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { sortOrderables } from "../../../helpers/data.helpers";
import { defaultLogoSvg } from "../../../helpers/view.helpers";
import { ISocialmediamenuitem } from "../../../models/api";
import { ApiService } from "../../../services/ApiService";
import { ViewTypeState } from "../../../state/ViewTypeState";


@Component({
    providers: [ApiService],
    selector: "app-menu-social",
    styleUrls: ["./menu-social.component.scss"],
    templateUrl: "./menu-social.component.html"
})
export class MenuSocialComponent {
    private readonly _apiService: ApiService;
    private readonly _notificationService: NotificationsService;

    private _failure: boolean;
    private _loading: boolean;
    private _logoSvg: string;
    private _socialMediaMenuItems: ISocialmediamenuitem[];

    public constructor(
        apiService: ApiService,
        notificationService: NotificationsService,
        viewTypeState: ViewTypeState
    ) {
        this._apiService = apiService;
        this._notificationService = notificationService;

        this._failure = false;
        this._loading = false;
        this._logoSvg = defaultLogoSvg;
        this._socialMediaMenuItems = [];

        viewTypeState
            .viewTypeActions
            .subscribe(vt => {
                if (vt) {
                    this._logoSvg = vt.logoSvg || defaultLogoSvg;
                }
            });

        this.getSocialMediaMenuItems();
    }

    public get failure(): boolean {
        return this._failure;
    }

    public get failureMessage(): string {
        return "Could not get social media.";
    }

    public get loading(): boolean {
        return this._loading;
    }

    public get logoSvg(): string {
        return this._logoSvg;
    }

    public get socialMediaMenuItems(): ISocialmediamenuitem[] {
        return this._socialMediaMenuItems;
    }

    private getSocialMediaMenuItems(): void {
        this._socialMediaMenuItems = [];
        this._failure = false;
        this._loading = true;

        this._apiService
            .getMany<ISocialmediamenuitem>("socialmediamenuitems")
            .pipe(
                catchError(error => {
                    this._failure = true;
                    this._notificationService.error("Could not get social media", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<ISocialmediamenuitem[]>([]);
                }),
                finalize(() => {
                    this._loading = false;
                })
            )
            .subscribe(socialMediaMenuItems => {
                this._socialMediaMenuItems = sortOrderables(socialMediaMenuItems);
            });
    }
}
