import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ROUTE_PARAM_TAG_NAME } from "../../constants/route-params.constants";

@Component({
    selector: "app-tag",
    styleUrls: ["./tag.component.scss"],
    templateUrl: "./tag.component.html"
})
export class TagComponent implements OnDestroy {
    private readonly _destroyed: Subject<void>;

    private _tagName: string;

    public constructor(
        route: ActivatedRoute
    ) {
        this._destroyed = new Subject<void>();

        this._tagName = "";

        route
            .params
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(routeParams => {
                const tagName = routeParams[ROUTE_PARAM_TAG_NAME];
                this._tagName = tagName || "";
            });
    }

    public ngOnDestroy(): void {
        this._destroyed.next(null);
        this._destroyed.complete();
    }

    public get tagName(): string {
        return this._tagName;
    }
}
