import { Component } from "@angular/core";
import { finalize } from "rxjs/operators";
import { splitRoute } from "../../helpers/data.helpers";
import { IPage } from "../../models/api";
import { ApiService } from "../../services/ApiService";

@Component({
    selector: "app-marquee",
    styleUrls: ["./marquee.component.scss"],
    templateUrl: "./marquee.component.html"
})
export class MarqueeComponent {

    private readonly _apiService: ApiService;

    private _loading: boolean;
    private _pages: IPage[];

    public constructor(
        apiService: ApiService
    ) {
        this._apiService = apiService;

        this._loading = false;
        this._pages = [];

        this.getLatestPages();
    }

    public get loading(): boolean {
        return this._loading;
    }

    public get pages(): IPage[] {
        return this._pages;
    }

    public routerLink(page: IPage): string[] {
        return splitRoute(page.url);
    }

    private getLatestPages(): void {
        this._loading = true;

        this._apiService.getMany<IPage>("pages", {
            _limit: 10,
            _sort: "createdAt:desc",
            isPost: true
        })
        .pipe(
            finalize(() => {
                this._loading = false;
            })
        )
        .subscribe(pages => {
            this._pages = pages;
        });
    }

}
