import { Component } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { sortOrderables } from "../../../helpers/data.helpers";
import { IBloglink } from "../../../models/api";
import { ApiService } from "../../../services/ApiService";

@Component({
    providers: [ApiService],
    selector: "app-blog-links-list",
    styleUrls: ["./blog-links-list.component.scss"],
    templateUrl: "./blog-links-list.component.html"
})
export class BlogLinksListComponent {
    private readonly _apiService: ApiService;
    private readonly _notificationService: NotificationsService;

    private _blogLinks: IBloglink[];
    private _failure: boolean;
    private _loading: boolean;

    public constructor(
        apiService: ApiService,
        notificationService: NotificationsService
    ) {
        this._apiService = apiService;
        this._notificationService = notificationService;

        this._blogLinks = [];
        this._failure = false;
        this._loading = false;

        this.getBlogLinks();
    }

    public get blogLinks(): IBloglink[] {
        return this._blogLinks;
    }

    public get failure(): boolean {
        return this._failure;
    }

    public get failureMessage(): string {
        return "Could not get blog links.";
    }

    public get loading(): boolean {
        return this._loading;
    }

    private getBlogLinks(): void {
        this._blogLinks = [];
        this._failure = false;
        this._loading = true;

        this._apiService
            .getMany<IBloglink>("bloglinks")
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not get blog links", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failure = true;

                    return of<IBloglink[]>([]);
                }),
                finalize(() => {
                    this._loading = false;
                })
            )
            .subscribe(blogLinks => {
                this._blogLinks = sortOrderables(blogLinks);
            });
    }
}
