import { ILegacyhtmlwidget, IWidgetbase } from "../api";
import { ILayoutJson } from "../api/layoutJson";

export class LegacyHtmlWidget implements IWidgetbase {
    public _id: string;
    public html: string;
    public layoutJson: ILayoutJson;

    public constructor(legacyHtmlWidget: ILegacyhtmlwidget) {
        this._id = legacyHtmlWidget._id;
        this.html = legacyHtmlWidget.html;
    }
}
