import { Component, Input } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of } from "rxjs";
import { catchError, finalize, flatMap } from "rxjs/operators";
import { IImagegallerywidgetitem } from "../../models/api";
import { ApiService } from "../../services/ApiService";
import { CommonUtils } from "../common/commonUtils";
import { ImageGalleryWidget } from "../../models/widgets/ImageGalleryWidget";

@Component({
    selector: "app-image-gallary",
    styleUrls: ["./image-gallary.component.scss"],
    templateUrl: "./image-gallary.component.html"
})
export class ImageGallaryComponent {

    private readonly LIMIT: number = 20;

    private readonly _apiService: ApiService;
    private readonly _notificationService: NotificationsService;

    private _currentResultPage: number;
    private _failure: boolean;
    private _imageGallery: ImageGalleryWidget;
    private _loading: boolean;
    private _start: number;
    private _total: number;

    public constructor(
        apiService: ApiService,
        notificationService: NotificationsService
    ) {
        this._apiService = apiService;
        this._notificationService = notificationService;

        this._failure = false;
        this._imageGallery = null;
        this._loading = false;
        this._start = 0;
        this._total = 0;
    }

    public get failure(): boolean {
        return this._failure;
    }

    public get failureMessage(): string {
        return "Could not get image gallery.";
    }

    public get imageGallery(): ImageGalleryWidget {
        return this._imageGallery;
    }

    @Input()
    public set imageGallery(v: ImageGalleryWidget) {
        if (v) {
            this._imageGallery = v;
            this.getImageGalleyItems();
        }
    }

    public get loading(): boolean {
        return this._loading;
    }

    public get resultsLimit(): number {
        return this.LIMIT;
    }

    public get totalResults(): number {
        return this._total;
    }

    public get currentResultPage(): number {
        return this._currentResultPage;
    }

    public set currentResultPage(v: number) {
        this._currentResultPage = v;
    }

    public pageResults(pageNumber: number): void {
        this._start = (pageNumber - 1) * this.LIMIT;
        this._currentResultPage = pageNumber;
        this.getImageGalleyItems();
    }

    public hasMultipleItems(imageGalleryItem: IImagegallerywidgetitem): boolean {
        return imageGalleryItem
            && imageGalleryItem.image
            && (imageGalleryItem.image as Blob[])
            && (imageGalleryItem.image as Blob[]).length > 1
            || false;
    }

    public hasThumbnail(imageGalleryItem: IImagegallerywidgetitem, index: number = 0): boolean {
        return imageGalleryItem
            && (
                (imageGalleryItem.thumbnail as Blob[] && imageGalleryItem.thumbnail[index] !== null)
                || imageGalleryItem.thumbnail !== null
            )
            ;
    }

    public getImageUrl(imageGalleryItem: IImagegallerywidgetitem, index: number = 0): string {
        const image = imageGalleryItem
            && imageGalleryItem.image
            && (imageGalleryItem.image as Blob[] && imageGalleryItem.image[index]) || imageGalleryItem.image;

        return CommonUtils.getImageUrl(image)
        || "";
    }

    public getThumbnailUrl(imageGalleryItem: IImagegallerywidgetitem, index: number = 0): string {
        const thumbnail = imageGalleryItem
            && imageGalleryItem.thumbnail
            && (imageGalleryItem.thumbnail as Blob[] && imageGalleryItem.thumbnail[index]) || imageGalleryItem.thumbnail;

        return thumbnail
            && CommonUtils.getImageUrl(thumbnail)
            || this.getImageUrl(imageGalleryItem, index);
    }

    private getImageGalleyItems(): void {
        this._imageGallery.imagegallerywidgetitems = [];
        this._loading = true;
        this._failure = false;

        this._apiService.count("imagegallerywidgetitems", {
            "imagegallerywidget._id": this._imageGallery._id
        })
            .pipe(
                flatMap(count => {
                    this._total = count;

                    return this._apiService.getMany<IImagegallerywidgetitem>("imagegallerywidgetitems", {
                        _limit: this.LIMIT,
                        _start: this._start,
                        "imagegallerywidget._id": this._imageGallery._id
                    });
                }),
                catchError(error => {
                    this._notificationService.error("Could not get image gallery", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failure = true;

                    return of<IImagegallerywidgetitem[]>([]);
                }),
                finalize(() => {
                    this._loading = false;
                })
            )
            .subscribe(imageGalleryItems => {
                this._imageGallery.imagegallerywidgetitems = imageGalleryItems;
                for (const imageGalleryItem of this._imageGallery.imagegallerywidgetitems) {
                    if (this.hasMultipleItems(imageGalleryItem)) {
                        this._total--;
                        this._total += (imageGalleryItem.image as Blob[]).length;
                    }
                }
            });
    }

}
