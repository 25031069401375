import { ImageAlignment } from "../../types/ImageAlignment";
import { IImagewidget, IWidgetbase } from "../api";
import { ILayoutJson } from "../api/layoutJson";

export class ImageWidget implements IWidgetbase {
    public _id: string;
    public alignment: ImageAlignment;
    public height: number;
    public image: Blob;
    public layoutJson: ILayoutJson;
    public text: string;
    public title: string;
    public titleUrl: string;
    public width: number;

    public constructor(imageWidget: IImagewidget) {
        this._id = imageWidget._id;
        this.alignment = imageWidget.alignment;
        this.height = imageWidget.height;
        this.image = imageWidget.image;
        this.text = imageWidget.text;
        this.title = imageWidget.title;
        this.titleUrl = imageWidget.titleUrl;
        this.width = imageWidget.width;
    }
}
