import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { NotificationsService } from "angular2-notifications";
import { Observable, throwError } from "rxjs";
import { catchError, skipWhile } from "rxjs/operators";
import { AuthState } from "../../../app/state/AuthState";
import { ErrorResponse } from "../../types/ErrorResponse";

@Injectable()
export class RestInterceptor implements HttpInterceptor {
    private readonly _authState: AuthState;
    private readonly _notificationService: NotificationsService;
    private readonly _router: Router;

    public constructor(
        authState: AuthState,
        notificationService: NotificationsService,
        router: Router,
    ) {
        this._authState = authState;
        this._notificationService = notificationService;
        this._router = router;
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            headers: request.headers.set("Authorization", "Bearer " + this._authState.accessToken)
        });
        return next
            .handle(request)
            .pipe(
                skipWhile(response => !(response instanceof HttpResponse)),
                catchError((errorResponse: HttpErrorResponse) => {
                    let message: string = null;

                    try {
                        // TODO: Figure out how the API will return custom error messages.
                        if (errorResponse.status === 401 && errorResponse.error) {
                            const apiErrorResponse = errorResponse.error as ErrorResponse;
                            message = apiErrorResponse.message;

                            if (
                                message
                                && message
                                    .toLowerCase()
                                    .indexOf("invalid token") > -1
                            ) {
                                //tell them the token expired and redirect to login
                                // this._authState.notifySessionExpired();
                                //TODO: anything?
                            }
                        }
                    }
                    catch (e) {
                        message = errorResponse.statusText;
                    }

                    return throwError(message || "An error occurred.");
                })
            );
    }
}