export const browserIsIE = () => {
    const userAgent = window.navigator.userAgent;
    let isIE = false;

    // IE <= 10
    if (userAgent.indexOf("MSIE") > -1) {
        isIE = true;
    }
    // IE 11
    else if (userAgent.indexOf("Trident") > -1) {
        isIE = true;
    }

    return isIE;
};
