import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { setTheme } from "../helpers/view.helpers";
import { IViewtype } from "../models/api";

@Injectable()
export class ViewTypeState {
    private readonly _viewTypeActions: BehaviorSubject<IViewtype>;

    public constructor() {
        this._viewTypeActions = new BehaviorSubject<IViewtype>(null);

        this.restore();
    }

    public get viewTypeActions(): Observable<IViewtype> {
        return this._viewTypeActions;
    }

    public next(viewType: IViewtype): void {
        if (viewType) {
            const viewTypeString = JSON.stringify(viewType);
            window.localStorage.setItem(environment.localStorageViewTypeName, viewTypeString);
            setTheme(viewType);
        }
        else {
            window.localStorage.removeItem(environment.localStorageViewTypeName);
        }

        this._viewTypeActions.next(viewType);
    }

    private restore(): void {
        const viewTypeString = window.localStorage.getItem(environment.localStorageViewTypeName);

        if (viewTypeString) {
            const viewType = JSON.parse(viewTypeString);
            this._viewTypeActions.next(viewType);
            setTheme(viewType);
        }
    }
}
