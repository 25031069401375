import { Component } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { IFavorite, IPage } from "../../models/api";
import { ApiService } from "../../services/ApiService";
import { AuthState } from "../../state/AuthState";

@Component({
    providers: [ApiService],
    selector: "app-favorites",
    styleUrls: ["./favorites.component.scss"],
    templateUrl: "./favorites.component.html"
})
export class FavoritesComponent {
    private readonly _apiService: ApiService;
    private readonly _authState: AuthState;
    private readonly _notificationService: NotificationsService;

    private _failureFavorites: boolean;
    private _favoritePages: IPage[];
    private _favoritePosts: IPage[];
    // private _favoriteTags: ITag[];
    private _loadingFavorites: boolean;

    public constructor(
        apiService: ApiService,
        authState: AuthState,
        notificationService: NotificationsService
    ) {
        this._apiService = apiService;
        this._authState = authState;
        this._notificationService = notificationService;

        this._failureFavorites = false;
        this._favoritePages = [];
        this._favoritePosts = [];
        // this._favoriteTags = [];
        this._loadingFavorites = false;

        this.getFavorites();
    }

    public get failureFavorites(): boolean {
        return this._failureFavorites;
    }

    public get failureFavoritesMessage(): string {
        return "Could not get favorites.";
    }

    public get favoritePages(): IPage[] {
        return this._favoritePages;
    }

    public get favoritePosts(): IPage[] {
        return this._favoritePosts;
    }

    // public get favoriteTags(): ITag[] {
    //     return this._favoriteTags;
    // }

    public get loadingFavorites(): boolean {
        return this._loadingFavorites;
    }

    private getFavorites(): void {
        this._favoritePages = [];
        this._favoritePosts = [];
        // this._favoriteTags = [];
        this._failureFavorites = false;
        this._loadingFavorites = true;

        this._apiService
            .getMany<IFavorite>("favorites", {
                "connectuser.email": this._authState.userEmail
            })
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not get favorites", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<IFavorite[]>([]);
                }),
                finalize(() => {
                    this._loadingFavorites = false;
                })
            )
            .subscribe(favorites => {
                if (favorites) {
                    const favoritePages = favorites
                        .filter(f => !!f.page)
                        .map(f => f.page);

                    this._favoritePages = favoritePages.filter(p => !p.isPost);
                    this._favoritePosts = favoritePages.filter(p => p.isPost);
                    // this._favoriteTags = favorites
                    //     .filter(f => !!f.tag)
                    //     .map(f => f.tag);
                }
            });
    }
}
