import { Component, Input } from "@angular/core";
import { ImageWidget } from "../../../models/widgets/ImageWidget";
import { CommonUtils } from "../../common/commonUtils";
import { splitRoute } from "../../../helpers/data.helpers";

@Component({
    selector: "app-image-widget",
    styleUrls: ["./image-widget.component.scss"],
    templateUrl: "./image-widget.component.html"
})
export class ImageWidgetComponent {
    private _imageWidget: ImageWidget;

    public constructor() {
        this._imageWidget = null;
    }

    public get alignment(): string {
        return this._imageWidget
            && this._imageWidget.alignment
            || "";
    }

    public get imageWidget(): ImageWidget {
        return this._imageWidget;
    }

    @Input()
    public set imageWidget(value: ImageWidget) {
        this._imageWidget = value;
    }

    public get imageUrl(): string {
        return this._imageWidget
        && this._imageWidget.image
        && CommonUtils.getImageUrl(this._imageWidget.image)
        || "";
    }

    public get margin(): string {
        switch (this._imageWidget.alignment) {
            case "left":
                return "uk-margin-right";
            case "right":
                return "uk-margin-left";
            default:
                return "uk-text-center";
        }
    }

    public get noAlignment(): boolean {
        return this.alignment.length === 0 || this.alignment === "none";
    }

    public get routerLink(): string[] {
        return splitRoute(this._imageWidget.titleUrl);
    }

    public get widthHeigth(): object {
        const height = this._imageWidget
            && this._imageWidget.height
            && `${this._imageWidget.height.toString()}px`
            || "100%";

        const width = this._imageWidget
            && this._imageWidget.width
            && `${this._imageWidget.width.toString()}px`
            || "100%";

        return { height, width };
    }
}
