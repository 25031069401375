import { AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from "@angular/core";
import { LegacyHtmlWidget } from "../../../models/widgets/LegacyHtmlWidget";

@Component({
    // Muse use ViewEncapsulation.ShadowDom to be shadow dom v1 compliant; v0 support has been removed.
    encapsulation: ViewEncapsulation.ShadowDom,
    selector: "app-legacy-html-widget",
    styleUrls: ["./legacy-html-widget.component.scss"],
    templateUrl: "./legacy-html-widget.component.html"
})
export class LegacyHtmlWidgetComponent implements AfterViewInit {
    @ViewChild("legacyHtmlWidgetContainer", { static: false })
    private readonly _legacyHtmlWidgetContainer: ElementRef;

    private _legacyHtmlWidget: LegacyHtmlWidget;
    private _legacyHtmlWidgetScriptContents: string[];

    public constructor() {
        this._legacyHtmlWidget = null;
        this._legacyHtmlWidgetScriptContents = [];
    }

    public ngAfterViewInit(): void {
        this.createScriptTags();
    }

    public get legacyHtmlWidget(): LegacyHtmlWidget {
        return this._legacyHtmlWidget;
    }

    @Input()
    public set legacyHtmlWidget(value: LegacyHtmlWidget) {
        this._legacyHtmlWidget = value || null;
        this.extractScriptContents();
        this.augmentDeepJquery();
    }

    private augmentDeepJquery(scriptContents: string = null): string {
        if (!scriptContents) {
            this._legacyHtmlWidgetScriptContents = this._legacyHtmlWidgetScriptContents.map(s => this.augmentDeepJquery(s));

            return;
        }

        const separatedScriptParts = scriptContents.split("$(");

        let newScriptContents = separatedScriptParts.shift();

        while (separatedScriptParts.length) {
            const scriptPart = separatedScriptParts.shift();

            // Note: this._legacyHtmlWidget._id is the host element's id (the HTML id of this component itself).
            newScriptContents += `$(document.getElementById("${this._legacyHtmlWidget._id}").shadowRoot).find(${scriptPart}`;
        }

        return newScriptContents;
    }

    private createScriptTags(): void {
        // Should not happen, but sanity check.
        if (!this._legacyHtmlWidgetContainer) {
            return;
        }

        this._legacyHtmlWidgetScriptContents.forEach(s => {
            const scriptElement = document.createElement("script");
            const scriptContentsElement = document.createTextNode(s);
            scriptElement.appendChild(scriptContentsElement);

            this._legacyHtmlWidgetContainer.nativeElement.appendChild(scriptElement);
        });
    }

    private extractScriptContents(): void {
        // Should not happen, but sanity check.
        if (!this._legacyHtmlWidget || !this._legacyHtmlWidget.html) {
            return;
        }

        const scriptOpeningTag = "<script>";
        const scriptClosingTag = "</script>";

        // [ "{html}", "{scriptContent}</script>{html}", "{scriptContent}</script>{html}", ... ]
        const htmlParts = this._legacyHtmlWidget.html.split(scriptOpeningTag);

        // "{html}"
        let newHtml = htmlParts.shift();

        while (htmlParts.length) {
            // "{scriptContent}</script>{html}"
            const currentScriptHtmlPart = htmlParts.shift();
            // [ "{scriptContent}", "{html}" ]
            const scriptHtmlParts = currentScriptHtmlPart.split(scriptClosingTag);

            // [ "{scriptContent}", ... ]
            this._legacyHtmlWidgetScriptContents.push(scriptHtmlParts[0]);
            // "{html}{html}..."
            newHtml += scriptHtmlParts[1];
        }

        this._legacyHtmlWidget.html = newHtml;
    }
}
