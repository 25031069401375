import { Component, Input } from "@angular/core";
import { splitRoute } from "../../helpers/data.helpers";
import { ILeftFocusedItem } from "../../models/api/leftFocusedItem";
import { CommonUtils } from "../common/commonUtils";

@Component({
    selector: "app-left-focus-item",
    styleUrls: ["./left-focus-item.component.scss"],
    templateUrl: "./left-focus-item.component.html"
})
export class LeftFocusItemComponent {

    private _leftFocusItem: ILeftFocusedItem;

    public constructor() {
        this._leftFocusItem = null;
    }

    public get leftFocusItem(): ILeftFocusedItem {
        return this._leftFocusItem;
    }

    @Input()
    public set leftFocusItem(v: ILeftFocusedItem) {
        this._leftFocusItem = v;
    }

    public get previewImage(): string {
        return this._leftFocusItem
        && this._leftFocusItem.page
        && this._leftFocusItem.page.previewImage
        && CommonUtils.getImageUrl(this._leftFocusItem.page.previewImage)
        || "";
    }

    public get routerLink(): string[] {
        return splitRoute(this._leftFocusItem.page.url);
    }

}
