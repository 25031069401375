import { Injectable } from "@angular/core";
import { OktaAuthService } from '@okta/okta-angular';

@Injectable()
export class AuthState {
    
    private _oktaAuth: OktaAuthService;
    private _isAuthenticated: boolean;
    private _userClaims;
    

    public constructor(oktaAuth: OktaAuthService) {
        this._oktaAuth = oktaAuth
        this._oktaAuth.$authenticationState.subscribe(authenticated => {
            this._isAuthenticated = authenticated
            if (authenticated) {
                this._oktaAuth.getUser()
                .then(claims => {
                    this._userClaims = claims;
                })
            }            
        });
    }

    public set userClaims(claims) {
        this._userClaims = claims;
    }

    public get userEmail(): string {
        if (typeof this._userClaims !== 'undefined') {
            return this._userClaims.email;
        }
    }

    public get userFirstName(): string {
        if (typeof this._userClaims !== 'undefined') {
            return this._userClaims.given_name;
        }
    }

    public get userFullName(): string {
        if (typeof this._userClaims !== 'undefined') {
            return this._userClaims.given_name + ' ' + this._userClaims.family_name;
        }
    }

    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    public get accessToken(): string {
        return this._oktaAuth.getAccessToken();
    }

}