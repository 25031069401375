import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { Observable, of, zip } from "rxjs";
import { catchError, finalize, flatMap } from "rxjs/operators";
import { OktaAuthService } from '@okta/okta-angular';
import { ModalDirective } from "../../../directives/modal.directive";
import { sortOrderables } from "../../../helpers/data.helpers";
import { CommonUI } from "../../../helpers/view.helpers";
import { ITopmenulink, IViewtype } from "../../../models/api";
import { ApiService } from "../../../services/ApiService";
import { AuthState } from "../../../state/AuthState";
import { ViewTypeState } from "../../../state/ViewTypeState";

declare let qmQuotes: any[];

@Component({
    providers: [ApiService, OktaAuthService],
    selector: "app-menu-top",
    styleUrls: ["./menu-top.component.scss"],
    templateUrl: "./menu-top.component.html"
})
export class MenuTopComponent implements AfterViewInit {
    @ViewChild("menuTopOffcanvas", { static: true })
    private readonly _menuTopOffcanvas: ElementRef;
    @ViewChild(ModalDirective, { static: true })
    private readonly _viewTypeModal: ModalDirective;
    @ViewChild("tmxNationModal", { static: true })
    private readonly _tmxNationModal: ElementRef;

    private readonly _apiService: ApiService;
    private readonly _oktaAuth: OktaAuthService;
    private readonly _authState: AuthState;
    private readonly _notificationService: NotificationsService;
    private readonly _viewTypeState: ViewTypeState;

    private _failure: boolean;
    private _loading: boolean;
    private _selectedViewType: IViewtype;
    private _selectedViewTypeOption: string;
    private _topMenuLinks: ITopmenulink[];
    private _viewTypes: IViewtype[];

    public constructor(
        apiService: ApiService,
        authState: AuthState,
        oktaAuth: OktaAuthService,
        notificationService: NotificationsService,
        viewTypeState: ViewTypeState,
    ) {
        this._apiService = apiService;
        this._oktaAuth = oktaAuth;
        this._authState = authState;
        this._notificationService = notificationService;
        this._viewTypeState = viewTypeState;

        this._failure = false;
        this._loading = false;
        this._selectedViewType = null;
        this._selectedViewTypeOption = null;
        this._topMenuLinks = [];
        this._viewTypes = [];

        this.getData();

        viewTypeState
            .viewTypeActions
            .subscribe(vt => {
                if (vt) {
                    this._selectedViewType = vt;
                }
            });
    }

    public ngAfterViewInit(): void {
        // If view type is not set, require user to select a view type.
        if (!this._selectedViewType) {
            this._viewTypeModal.show();
        }
        else {
            this._selectedViewTypeOption = this._selectedViewType.id;
        }

        if (this.canShowTmxNationModal) {
            this.showTmxNationModal();
        }
    }

    public get failure(): boolean {
        return this._failure;
    }

    public get failureMessage(): string {
        return "Could not get menu items.";
    }

    public get loading(): boolean {
        return this._loading;
    }

    public get selectedViewType(): IViewtype {
        return this._selectedViewType;
    }

    public get selectedViewTypeOption(): string {
        return this._selectedViewTypeOption;
    }

    public set selectedViewTypeOption(value: string) {
        this._selectedViewTypeOption = value;
    }

    public get topMenuLinks(): ITopmenulink[] {
        return this._topMenuLinks;
    }

    public get userEmail(): string {
        return this._authState.userEmail;
    }

    public get userFirstName(): string {
        return this._authState.userFirstName;
    }

    public get viewTypes(): IViewtype[] {
        return this._viewTypes;
    }

    public logout(): void {
        this._oktaAuth.signOut();
    }

    public resetSelectedViewTypeOption(): void {
        if (this._selectedViewTypeOption) {
            this._selectedViewTypeOption = this._selectedViewType.id;
            this._viewTypeModal.hide();
        }
    }

    public setViewType(viewTypeId: string): void {
        const selectedViewType = this._viewTypes.find(vt => vt.id === viewTypeId);

        if (selectedViewType) {
            this._viewTypeState.next(selectedViewType);
            this._viewTypeModal.hide();
        }
    }

    public showCategoryNavBar(viewType: string): void {
        CommonUI.showTerminixNavBar = true;
    }

    public showOffcanvas(): void {
        UIkit.offcanvas(this._menuTopOffcanvas.nativeElement)
            .show();
    }

    public showViewTypeModal(): void {
        this._viewTypeModal.show();
    }

    public get canShowTmxNationModal(): boolean {
        let canShow = true;
        let dismissUntil = window.localStorage.getItem("tmxNationModalDismissUntil");

        if (dismissUntil) {
            let dismissDt = new Date(JSON.parse(dismissUntil));
            let now = new Date();
            if (dismissDt > now) {
                canShow = false;
            }
        }

        return canShow;
    }
    public showTmxNationModal(): void {
        UIkit.modal(document.getElementById("tmx-nation-modal")).show();
    }

    public hideTmxNationModal(): void {
        UIkit.modal(document.getElementById("tmx-nation-modal")).hide();
    }

    public dismissTmxNationModal(): void {
        let dismiss = new Date();
        dismiss.setDate(dismiss.getDate() + 7);
        window.localStorage.setItem("tmxNationModalDismissUntil", JSON.stringify(dismiss));
        this.hideTmxNationModal();
    }

    private getData(): void {
        this._failure = false;
        this._loading = true;

        zip(this.getTopMenuLinks(), this.getViewTypes())
            .pipe(
                finalize(() => {
                    this._loading = false;
                })
            )
            .subscribe();
    }

    private getTopMenuLinks(): Observable<void> {
        this._topMenuLinks = [];
        if ( this._authState.isAuthenticated) {
            return this._apiService
                .getMany<ITopmenulink>("topmenulinks")
                .pipe(
                    catchError(error => {
                        this._failure = true;
                        this._notificationService.error("Could not get top menu links", error);
                        // TODO: angular2-notifications does not support Angular 8 right now.
                        // Remove this when it does.
                        UIkit.notification(error, { status: "danger" });

                        return of<ITopmenulink[]>([]);
                    }),
                    flatMap(topMenuLinks => {
                        this._topMenuLinks = sortOrderables(topMenuLinks);

                        return of(null);
                    })
                );
        }
    }

    private getViewTypes(): Observable<void> {
        this._viewTypes = [];
        if ( this._authState.isAuthenticated) {
        return this._apiService
            .getMany<IViewtype>("viewtypes")
            .pipe(
                catchError(error => {
                    this._failure = true;
                    this._notificationService.error("Could not get view options", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<IViewtype[]>([]);
                }),
                flatMap(viewTypes => {
                    this._viewTypes = sortOrderables(viewTypes);

                    return of(null);
                })
            );
        };
    }

    private qmDecimal(qmValue: any): any {
        qmValue = qmValue.replace(/[^\d.-]/g, "");
        qmValue = parseFloat(qmValue);
        qmValue = qmValue.toFixed(2);
        if (!isNaN(qmValue)) {
            return qmValue;
        }
        else {
            return "";
        }
    }

    private qmText(qmValue: any): any {
        qmValue = qmValue.replace(/(<([^>]+)>)/ig, "");
        qmValue = qmValue.replace(/Quote Time/g, "");
        qmValue = qmValue.replace(/Volume/g, "");

        return qmValue;
    }
}
