import { Pipe, Sanitizer } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({ name: "safe" })
export class SanitizedHtml {
    private readonly _sanitizer: DomSanitizer;
    public constructor(sanitizer: DomSanitizer) {
        this._sanitizer = sanitizer;
    }

    public transform(html: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(html);
    }
}