import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { of, Subject } from "rxjs";
import { catchError, finalize, flatMap, map, takeUntil } from "rxjs/operators";
import { augmentQueryParams } from "../../helpers/data.helpers";
import { IPage, IPoll } from "../../models/api";
import { IHomewidget } from "../../models/api/homewidget";
import { ILeftFocusedItem } from "../../models/api/leftFocusedItem";
import { IVideo } from "../../models/api/video";
import { pagesQuery, pagesQueryParams, remainderPagesQueryParams } from "../../queries/page.queries";
import { ApiService } from "../../services/ApiService";
import { ViewTypeState } from "../../state/ViewTypeState";

const PAGE_PREVIEWS_LIMIT = 5;

@Component({
    providers: [ApiService],
    selector: "app-home",
    styleUrls: ["./home.component.scss"],
    templateUrl: "./home.component.html"
})
export class HomeComponent implements OnDestroy {
    @ViewChild("#cntdown", { static: false })
    private readonly _cntDown: ElementRef;

    private readonly _apiService: ApiService;
    private readonly _categoryIds: string;
    private readonly _destroyed: Subject<void>;
    private readonly _notificationService: NotificationsService;

    private _failureFeaturedPages: boolean;
    private _failureHomeWidget: boolean;
    private _failureLeftFocusItems: boolean;
    private _failurePages: boolean;
    private _failurePoll: boolean;
    private _failurePosts: boolean;
    private _failureVideos: boolean;
    private _featuredPages: IPage[];
    private _homeWidget: IHomewidget;
    private _leftFocusedItems: ILeftFocusedItem[];
    private _loadingFeaturedPages: boolean;
    private _loadingHomeWidget: boolean;
    private _loadingLeftFocusItems: boolean;
    private _loadingPages: boolean;
    private _loadingPoll: boolean;
    private _loadingPosts: boolean;
    private _loadingVideos: boolean;
    private _pages: IPage[];
    private _poll: IPoll;
    private _posts: IPage[];
    private _videos: IVideo[];

    public constructor(
        apiService: ApiService,
        notificationService: NotificationsService,
        viewTypeState: ViewTypeState
    ) {
        this._apiService = apiService;
        this._destroyed = new Subject<void>();
        this._notificationService = notificationService;

        this._failureFeaturedPages = false;
        this._failureHomeWidget = false;
        this._failureLeftFocusItems = false;
        this._failurePages = false;
        this._failurePoll = false;
        this._failurePosts = false;
        this._failureVideos = false;
        this._featuredPages = [];
        this._homeWidget = null;
        this._leftFocusedItems = [];
        this._loadingFeaturedPages = false;
        this._loadingHomeWidget = false;
        this._loadingLeftFocusItems = false;
        this._loadingPages = false;
        this._loadingPoll = false;
        this._loadingPosts = false;
        this._loadingVideos = false;
        this._pages = [];
        this._poll = null;
        this._posts = [];
        this._videos = [];

        viewTypeState
            .viewTypeActions
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(vt => {
                if (vt) {
                    // These should not be null, but for the sake of a sanity check...
                    const categoryIds = vt.categories && vt.categories.map(c => c.id) || null;

                    this.getFeaturedPages(categoryIds);
                    this.getLeftFocusItems();
                    //   this.getPages(categoryIds);
                    this.getPosts(categoryIds);
                    this.getVideos(categoryIds);
                    this.getPoll();
                    this.getHomeWidget();
                }
            });

        // Scroll to the top (in case of navigating from bottom menu links).
        window.scroll(0, 0);
    }

    public ngOnDestroy(): void {
        this._destroyed.next(null);
        this._destroyed.complete();
    }

    public get failureFeaturedPages(): boolean {
        return this._failureFeaturedPages;
    }

    public get failureFeaturedPagesMessage(): string {
        return "Could not get featured pages.";
    }

    public get failureHomeWidget(): boolean {
        return this._failureHomeWidget;
    }

    public get failureHomeWidgetMessage(): string {
        return "Could not get home widget.";
    }

    public get failureLeftFocusItems(): boolean {
        return this._failureLeftFocusItems;
    }

    public get failureLeftFocusItemsMessage(): string {
        return "Could not get left focus items.";
    }

    public get failurePages(): boolean {
        return this._failurePages;
    }

    public get failurePagesMessage(): string {
        return "Could not get recent pages.";
    }

    public get failurePoll(): boolean {
        return this._failurePoll;
    }

    public get failurePollMessage(): string {
        return "Could not get recent poll.";
    }

    public get failurePosts(): boolean {
        return this._failurePosts;
    }

    public get failurePostsMessage(): string {
        return "Could not get recent posts.";
    }

    public get failureVideos(): boolean {
        return this._failureVideos;
    }

    public get failureVideosMessage(): string {
        return "Could not get recent videos.";
    }

    public get featuredPages(): IPage[] {
        return this._featuredPages;
    }

    public get hasLeftFocusItems(): boolean {
        return this._leftFocusedItems && this._leftFocusedItems.length > 0;
    }

    public get hasTwoLeftFocusItems(): boolean {
        return this._leftFocusedItems && this._leftFocusedItems.length > 1;
    }

    public get leftFocusedItems(): ILeftFocusedItem[] {
        return this._leftFocusedItems;
    }

    public get loadingFeaturedPages(): boolean {
        return this._loadingFeaturedPages;
    }

    public get loadingHomeWidget(): boolean {
        return this._loadingHomeWidget;
    }

    public get loadingLeftFocusItems(): boolean {
        return this._loadingLeftFocusItems;
    }

    public get loadingPages(): boolean {
        return this._loadingPages;
    }

    public get loadingPoll(): boolean {
        return this._loadingPoll;
    }

    public get loadingPosts(): boolean {
        return this._loadingPosts;
    }

    public get loadingVideos(): boolean {
        return this._loadingVideos;
    }

    public get homeWidget(): IHomewidget {
        return this._homeWidget;
    }

    public get homeWidgetDate(): string {
        return this._homeWidget
            && this._homeWidget.CountDownDate
            && this._homeWidget.CountDownDate.toDateString()
            || "";
    }

    public get pages(): IPage[] {
        return this._pages;
    }

    public get poll(): IPoll {
        return this._poll;
    }

    public get posts(): IPage[] {
        return this._posts;
    }

    public get videos(): IVideo[] {
        return this._videos;
    }

    private getFeaturedPages(categoryIds: string[]): void {
        this._featuredPages = [];
        this._failureFeaturedPages = false;
        this._loadingFeaturedPages = true;

        const currentDate = new Date();

        this._apiService
            .query<IPage>(
                pagesQuery(false, {
                    limit: 1,
                    sort: "featuredStart:desc"
                }),
                pagesQueryParams(categoryIds, false, {
                    featuredUntil_gte: `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`
                })
            )
            .pipe(
                map(response => {
                    return response.data && response.data.pages || [];
                }),
                flatMap(pages => {
                    const remainderLimit = PAGE_PREVIEWS_LIMIT - pages.length;

                    if (!remainderLimit) {
                        return of(pages);
                    }

                    this._featuredPages.push(...pages);
                    const featuredIds = pages.map(p => p._id);

                    return this._apiService
                        .query<IPage>(
                            pagesQuery(false, {
                                limit: remainderLimit,
                                sort: "featuredStart:desc"
                            }),
                            remainderPagesQueryParams(categoryIds, featuredIds)
                        )
                        .pipe(
                            map(response => {
                                return response.data && response.data.pages || [];
                            })
                        );
                }),
                catchError(error => {
                    this._notificationService.error("Could not get featured pages", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failureFeaturedPages = true;

                    return of<IPage[]>([]);
                }),
                finalize(() => {
                    this._loadingFeaturedPages = false;
                })
            )
            .subscribe(pages => {
                this._featuredPages.push(...pages);
            });
    }

    private getHomeWidget(): void {
        this._homeWidget = null;
        this._loadingHomeWidget = true;
        this._failureHomeWidget = false;

        this._apiService.find<IHomewidget>("homewidgets", {
            _limit: 1,
            Display: true
        })
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not get home widget", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failureHomeWidget = true;

                    return of<IHomewidget[]>([]);
                }),
                finalize(() => {
                    this._loadingHomeWidget = false;
                })
            )
            .subscribe(result => {
                if (result && result.length) {
                    this._homeWidget = result[0];
                    if (this._homeWidget.CountDownDate) {
                        let date = (this._homeWidget.CountDownDate as any).substring(0, 10)
                            .split("-");
                        date = `${date[1]}-${date[2]}-${date[0]}`;
                        this._homeWidget.CountDownDate = new Date(date);
                    }
                }
            });
    }

    private getLeftFocusItems(): void {
        this._leftFocusedItems = [];
        this._failureLeftFocusItems = false;
        this._loadingLeftFocusItems = true;

        this._apiService.getMany<ILeftFocusedItem>("leftfocuseditems", {
            _limit: 2,
            _sort: "createdAt:desc",
            page_null: false
        })
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not get left focus items", error);
                    UIkit.notification(error, { status: "danger" });
                    this._failureLeftFocusItems = true;

                    return of<ILeftFocusedItem[]>([]);
                }),
                finalize(() => {
                    this._loadingLeftFocusItems = false;
                })
            )
            .subscribe(results => {
                if (results && results.length) {
                    this._leftFocusedItems = results;
                }
            });
    }

    private getPages(categoryIds: string[]): void {
        this._pages = [];
        this._failurePages = false;
        this._loadingPages = true;

        this._apiService
            .query<IPage>(
                pagesQuery(false, {
                    limit: PAGE_PREVIEWS_LIMIT,
                    sort: "createdAt:desc"
                }),
                pagesQueryParams(categoryIds)
            )
            .pipe(
                map(response => {
                    return response.data && response.data.pages || [];
                }),
                catchError(error => {
                    this._notificationService.error("Could not get recent pages", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failurePages = true;

                    return of<IPage[]>([]);
                }),
                finalize(() => {
                    this._loadingPages = false;
                })
            )
            .subscribe(pages => {
                this._pages = pages;
            });
    }

    private getPoll(): void {
        this._poll = null;
        this._loadingPoll = true;
        this._failurePoll = false;

        const currentDate: Date = new Date();

        this._apiService.find<IPoll>("polls", {
            _limit: 1,
            _sort: "start:DESC",
            end_gte: `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`,
            start_lte: `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`
        })
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not get recent poll", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failurePoll = true;

                    return of<IPoll[]>([]);
                }),
                finalize(() => {
                    this._loadingPoll = false;
                })
            )
            .subscribe(result => {
                if (result && result.length) {
                    this._poll = result[0];
                }
            });
    }

    private getPosts(categoryIds: string[]): void {
        this._posts = [];
        this._failurePosts = false;
        this._loadingPosts = true;

        const currentDate = new Date();

        this._apiService
            .query<IPage>(
                pagesQuery(false, {
                    limit: PAGE_PREVIEWS_LIMIT,
                    sort: "featuredStart:desc"
                }),
                pagesQueryParams(categoryIds, true, {
                    featuredUntil_gte: `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`
                })
            )
            .pipe(
                map(response => {
                    return response.data && response.data.pages || [];
                }),
                flatMap(posts => {
                    const remainderLimit = PAGE_PREVIEWS_LIMIT - posts.length;

                    if (!remainderLimit) {
                        return of(posts);
                    }

                    this._posts.push(...posts);
                    const featuredIds = posts.map(p => p._id);

                    return this._apiService
                        .query<IPage>(
                            pagesQuery(false, {
                                limit: remainderLimit,
                                sort: "featuredStart:desc"
                            }),
                            remainderPagesQueryParams(categoryIds, featuredIds, true)
                        )
                        .pipe(
                            map(response => {
                                return response.data && response.data.pages || [];
                            })
                        );
                }),
                catchError(error => {
                    this._notificationService.error("Could not get recent posts", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failurePosts = true;

                    return of<IPage[]>([]);
                }),
                finalize(() => {
                    this._loadingPosts = false;
                })
            )
            .subscribe(posts => {
                this._posts.push(...posts);
            });
    }

    private getVideos(categoryIds: string[]): void {
        this._loadingVideos = true;
        this._failureVideos = false;

        const queryParams = augmentQueryParams({
            _limit: 5,
            _sort: "createdAt:desc",
            Featured: true
        }, {
            categories_in: categoryIds
        });

        this._apiService.find<IVideo>("videos", queryParams)
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not get recent videos", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failureVideos = true;

                    return of<IVideo[]>([]);
                }),
                finalize(() => {
                    this._loadingVideos = false;
                })
            )
            .subscribe(result => {
                this._videos = result;
            });
    }
}
