import { Orderable } from "../types/Orderable";
import { QueryParams } from "../types/QueryParams";
import { IWidgetbase } from "../models/ap\i";
import { ILayoutJson } from "../models/api/layoutJson";

export const augmentQueryParams = (originalQueryParams: QueryParams, updateQueryParams: QueryParams) => {
    if (originalQueryParams) {
        updateQueryParams = {
            ...originalQueryParams,
            ...updateQueryParams
        };
    }

    return updateQueryParams;
};

export const sortAlpha = <T>(input: T[], keyAccessor: (o: T) => string) => {
    return input.sort((a, b) => {
        // Case-insensitive sorting.
        const aValue = keyAccessor(a)
            .toLowerCase();
        const bValue = keyAccessor(b)
            .toLowerCase();

        if (aValue < bValue) {
            return -1;
        }

        if (aValue > bValue) {
            return 1;
        }

        return 0;
    });
};

export const layoutCss = (layoutJson: ILayoutJson, isMobleView: boolean = false) => {
    if (layoutJson && !isMobleView) {
        const x = layoutJson.x + 1;
        const y = layoutJson.y + 1;
        const w = layoutJson.w;
        const h = layoutJson.h;

        return {
            "grid-column-end": x + w,
            "grid-column-start": x,
            "grid-row-end": y + h,
            "grid-row-start": y
        };
    }

    return {
        "grid-column-end": 12,
        "grid-column-start": 1
    };
};

export const sortOrderables = <T extends Orderable>(orderables: T[]) => {
    return orderables.sort((a, b) => {
        const aOrder = a.order || 0;
        const bOrder = b.order || 0;

        if (aOrder < bOrder) {
            return -1;
        }

        if (aOrder > bOrder) {
            return 1;
        }

        return 0;
    });
};

export const sortLayout = <T extends IWidgetbase>(orderables: T[]) => {
    return orderables.sort((a, b) => {
        const aOrder = a.layoutJson && (a.layoutJson.y) || 0;
        const bOrder = b.layoutJson && (b.layoutJson.y) || 0;

        if (aOrder < bOrder) {
            return -1;
        }

        if (aOrder > bOrder) {
            return 1;
        }

        return 0;
    });
};

export const splitRoute = (url: string) => {
    if (!url) {
        return ["/404"];
    }

    const segments = url.split("/");

    // First segment must begin with a slash so the path is absolute.
    segments[0] = `/${segments[0]}`;

    return segments;
};
