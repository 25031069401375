import { Component } from "@angular/core";
import { NotificationsService } from "angular2-notifications";
import { finalize, flatMap } from "rxjs/operators";
import { augmentQueryParams, splitRoute } from "../../..//helpers/data.helpers";
import { IMainmenusubmenuitem, IViewtype } from "../../../models/api";
import { ICategorymainmenuitem } from "../../../models/api/categorymainmenuitem";
import { ApiService } from "../../../services/ApiService";
import { environment } from "../../../../environments/environment";
import { of, Observable, zip, from } from "rxjs";
import { AuthState } from "../../../state/AuthState"

const DROPDOWN_COLUMN_BREAK_THRESHOLD = 15;

@Component({
    providers: [ApiService],
    selector: "app-view-nav-bar",
    styleUrls: ["./view-nav-bar.component.scss"],
    templateUrl: "./view-nav-bar.component.html"
})
export class ViewNavBarComponent {

    private readonly _apiService: ApiService;
    private readonly _notificationService: NotificationsService;
    private readonly _authState: AuthState

    private _display: boolean;
    private _menuItems: ICategorymainmenuitem[];

    public constructor(
        apiService: ApiService,
        notificationService: NotificationsService,
        authState: AuthState
    ) {
        this._apiService = apiService;
        this._notificationService = notificationService;
        this._authState = authState;

        this._display = false;
        this._menuItems = [];
        this.getNavBarItems();
    }

    public get displayNav(): boolean {
        //const show = this._display && CommonUI.showTerminixNavBar;

        return true;
    }

    public get dropdownPositionAttribute(): string {
        const pos = "bottom-right";

        return `{ pos: '${pos}' }`;
    }

    public get menuItems(): ICategorymainmenuitem[] {
        return this._menuItems;
    }

    public breakDropdownColumns(menuItem: ICategorymainmenuitem): boolean {
        if (!menuItem.mainmenusubmenuitems) {
            return false;
        }

        return menuItem.mainmenusubmenuitems.length > DROPDOWN_COLUMN_BREAK_THRESHOLD;
    }

    public getMenuItemRouterLink(menuItem: ICategorymainmenuitem): string[] {
        return splitRoute(menuItem.page.url);
    }

    public getSubMenuItemRouterLink(subMenuItem: IMainmenusubmenuitem): string[] {
        return splitRoute(subMenuItem.page.url);
    }

    private getNavBarItems(): void {

        this._display = false;
        //if ( this._authState.isAuthenticated) {
        this._apiService
            .findOne<IViewtype>("viewtypes", environment.terminixViewTypeId)
            .pipe(
                finalize(() => {
                    this._display = true;
                }),
                flatMap(result => {
                    if (result) {
                        const categoryMenuItemsQueryParams = augmentQueryParams({
                            _sort: "order:ASC"
                        }, {
                            categories_in: result.categories.map(c => c.id)
                        });

                        return this._apiService
                            .getMany<ICategorymainmenuitem>("categorymenuitems", categoryMenuItemsQueryParams);
                    }

                    return of<ICategorymainmenuitem[]>(null);
                }),
                flatMap(results => {
                    if (results && results.length) {
                        this._menuItems = results;
                        const obs: Observable<IMainmenusubmenuitem>[] = [];

                        for (const menuItem of results) {
                            for (const subMenuItem of menuItem.mainmenusubmenuitems) {
                                obs.push(this._apiService.findOne<IMainmenusubmenuitem>("mainmenusubmenuitems", subMenuItem.id));
                            }
                        }

                        return zip(...obs);
                    }

                    return of<IMainmenusubmenuitem[]>(null);

                })
            )
            .subscribe(results => {
                if (results && results.length) {
                    for (const subMenuItem of results) {
                        for (const menuItem of this._menuItems) {
                            if (menuItem.mainmenusubmenuitems) {
                                const index = menuItem.mainmenusubmenuitems.findIndex(msi => msi.id === subMenuItem.id);
                                if (index > -1) {
                                    menuItem.mainmenusubmenuitems[index] = subMenuItem;
                                    break;
                                }
                            }
                        }
                    }
                }
            });
        //};
    }

}
