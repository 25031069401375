import { IJsonFormAttributeDto } from "./interfaces/IJsonFormAttributeDto";

export class JsonFormDto {
    public attributes: IJsonFormAttributeDto[];
    public emailTo: string;
    public sourceIP: string;
    public title: string;

    public constructor(dto?: JsonFormDto) {
        this.attributes = dto && dto.attributes && dto.attributes.length && [...dto.attributes] || [];
        this.emailTo = dto && dto.emailTo || "";
        this.sourceIP = dto && dto.sourceIP || "";
        this.title = dto && dto.title || "";
    }
}