export const environment = {
    apiBaseUrl: "https://test.servsmart.io/cms",
    localStorageAdfsUserName: "smConnectAdfsUserTest",
    localStorageViewTypeName: "smConnectViewTypeTest",
    production: false,
    terminixViewTypeId: "5d8bf91466521c220c6465ac",
    tokenRefreshInterval: 3600000,
    oktaConfig: {
        issuer: 'https://terminix.okta.com/oauth2/ausop5p8qGcoyx9iI5d6',
        clientId : '0oa14x00rerhiwsou5d7',
        redirectUri: 'https://test.connect.terminix.com/login',
        postLogoutRedirectUri: 'https://test.connect.terminix.com',
        tokenManager: {
                storage: 'localStorage'
            },
        scopes: ["openid", "profile", "CMS.Read", "email"]
    }
};