import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { OktaCallbackComponent, OktaAuthGuard } from "@okta/okta-angular";
import { SearchComponent } from "./components/common/search/search.component";
import { FavoritesComponent } from "./components/favorites/favorites.component";
import { HomeComponent } from "./components/home/home.component";
import { PageComponent } from "./components/page/page.component";
import { PagesComponent } from "./components/pages/pages.component";
import { ShareStoryComponent } from "./components/share-story/share-story.component";
import { TagComponent } from "./components/tag/tag.component";
import { TagsComponent } from "./components/tags/tags.component";
import { ROUTE_PARAM_TAG_NAME } from "./constants/route-params.constants";

// tslint:disable: object-literal-sort-keys
const routes: Routes = [
    // Public routes.
    {
        path: "login",
        component: OktaCallbackComponent
    },
    // Authenticated routes.
    {
        canActivate: [ OktaAuthGuard ],
        path: "",
        children: [
            {
                path: "",
                component: HomeComponent
            },
            {
                path: "favorites",
                component: FavoritesComponent
            },
            {
                path: "share-your-story",
                component: ShareStoryComponent
            },
            {
                path: "search",
                children: [
                    {
                        path: "tags/:tagId",
                        component: SearchComponent
                    },
                    {
                        path: "categories/:categoryId",
                        component: SearchComponent
                    },
                    {
                        path: "moreNews",
                        component: SearchComponent
                    }
                ]
            },
            {
                path: "pages",
                children: [
                    {
                        path: "",
                        component: PagesComponent
                    }
                ]
            },
            {
                path: "tags",
                children: [
                    {
                        path: "",
                        component: TagsComponent
                    },
                    {
                        path: `:${ROUTE_PARAM_TAG_NAME}`,
                        children: [
                            {
                                path: "",
                                component: TagComponent
                            },
                            {
                                path: "pages",
                                component: PagesComponent
                            }
                        ]
                    }
                ]
            },
            {
                path: "**",
                component: PageComponent
            }
        ]
    }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {
}
