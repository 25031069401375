import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ROUTE_PARAM_TAG_NAME } from "../../constants/route-params.constants";
import { augmentQueryParams } from "../../helpers/data.helpers";
import { pagesQuery, pagesQueryParams } from "../../queries/page.queries";
import { ViewTypeState } from "../../state/ViewTypeState";
import { QueryParams } from "../../types/QueryParams";

/**
 * OBSOLETE. Currently searching pages without isPost distinction.
 */
@Component({
    selector: "app-posts",
    styleUrls: ["./posts.component.scss"],
    templateUrl: "./posts.component.html"
})
export class PostsComponent implements OnDestroy {
    private readonly _destroyed: Subject<void>;

    private _queryParams: QueryParams;

    public constructor(
        route: ActivatedRoute,
        viewTypeState: ViewTypeState
    ) {
        this._destroyed = new Subject<void>();

        this._queryParams = {};

        route
            .params
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(routeParams => {
                const tagName = routeParams[ROUTE_PARAM_TAG_NAME];

                if (tagName) {
                    this._queryParams = augmentQueryParams(
                        this._queryParams,
                        {
                            tags: {
                                name: tagName
                            }
                        }
                    );
                }
            });

        viewTypeState
            .viewTypeActions
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(vt => {
                if (vt) {
                    // These should not be null, but for the sake of a sanity check...
                    const categoryIds = vt.categories && vt.categories.map(c =>c.id) || null;

                    this._queryParams = augmentQueryParams(
                        this._queryParams,
                        pagesQueryParams(categoryIds)
                    );
                }
            });
    }

    public ngOnDestroy(): void {
        this._destroyed.next(null);
        this._destroyed.complete();
    }

    public get query(): string {
        return pagesQuery();
    }

    public get queryParams(): QueryParams {
        return this._queryParams;
    }
}
