import { Directive, ElementRef, OnDestroy } from "@angular/core";

@Directive({
    selector: "[modal]"
})
export class ModalDirective implements OnDestroy {
    private readonly _element: ElementRef;

    public constructor(
        element: ElementRef
    ) {
        this._element = element;
    }

    public ngOnDestroy(): void {
        $(this._element.nativeElement)
            .remove();
    }

    public hide(): void {
        UIkit.modal(this._element.nativeElement)
            .hide();
    }

    public show(): void {
        UIkit.modal(this._element.nativeElement)
            .show();
    }
}
