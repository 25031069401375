import { QueryParams } from "../types/QueryParams";

export const commentsQuery = () => `
    query($where: JSON) {
        comments(
            where: $where
        ) {
            _id,
            connectuser {
                _id,
                email,
                fullName,
                shortName
            },
            createdAt,
            replies {
                _id
            },
            text,
            updatedAt
        }
    }
`;

const commentsQueryParams = (parentEntityName: string, parentEntityId: string): QueryParams => {
    const queryParams: QueryParams = {};

    queryParams[parentEntityName] = {
        _id: parentEntityId
    };

    return queryParams;
};

export const pageCommentsQueryParams = (pageId: string) => commentsQueryParams("page", pageId);

export const repliesQueryParams = (commentId: string) => commentsQueryParams("parentComment", commentId);

export const userCommentsQueryParams = (userId: string) => commentsQueryParams("connectuser", userId);
