import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NotificationsService } from "angular2-notifications";
import { of, Subject, throwError, zip } from "rxjs";
import { catchError, finalize, flatMap, map, takeUntil } from "rxjs/operators";
import { layoutCss, sortLayout } from "../../helpers/data.helpers";
import { IAccordion, IAccordionitem, IComment, IConnectuser, IFavorite, ILike, IPage, IWidgetbase } from "../../models/api";
import { ILayoutJson } from "../../models/api/layoutJson";
import { IModelLayout } from "../../models/api/modelLayout";
import { ImageGalleryWidget } from "../../models/widgets/ImageGalleryWidget";
import { ImageWidget } from "../../models/widgets/ImageWidget";
import { JsonFormWidget } from "../../models/widgets/JsonFormWidget";
import { LegacyHtmlWidget } from "../../models/widgets/LegacyHtmlWidget";
import { RichTextWidget } from "../../models/widgets/RichTextWidget";
import { Video } from "../../models/widgets/Video";
import { commentsQuery, pageCommentsQueryParams } from "../../queries/comment.queries";
import { ApiService } from "../../services/ApiService";
import { AuthState } from "../../state/AuthState";

@Component({
    providers: [ApiService],
    selector: "app-page",
    styleUrls: ["./page.component.scss"],
    templateUrl: "./page.component.html"
})
export class PageComponent implements OnDestroy {
    private readonly _apiService: ApiService;
    private readonly _authState: AuthState;
    private readonly _destroyed: Subject<void>;
    private readonly _mobileWidth: number = 959;
    private readonly _notificationService: NotificationsService;

    private _comments: IComment[];
    private _failureAddComment: boolean;
    private _failureComments: boolean;
    private _failureFavorite: boolean;
    private _failureLike: boolean;
    private _failurePage: boolean;
    private _favorite: IFavorite;
    private _like: ILike;
    private _loadingComments: boolean;
    private _loadingFavorite: boolean;
    private _loadingLike: boolean;
    private _loadingPage: boolean;
    private _newComment: IComment;
    private _page: IPage;
    private _savingComment: boolean;
    private _showCommentForm: boolean;
    private _showComments: boolean;
    private _widgets: IWidgetbase[];

    public constructor(
        apiService: ApiService,
        authState: AuthState,
        notificationService: NotificationsService,
        route: ActivatedRoute
    ) {
        this._apiService = apiService;
        this._authState = authState;
        this._destroyed = new Subject<void>();
        this._notificationService = notificationService;

        this._comments = [];
        this._failureAddComment = false;
        this._failureComments = false;
        this._failureFavorite = false;
        this._failureLike = false;
        this._failurePage = false;
        this._favorite = null;
        this._like = null;
        this._loadingComments = false;
        this._loadingFavorite = false;
        this._loadingLike = false;
        this._loadingPage = false;
        this._newComment = <IComment>{};
        this._page = null;
        this._savingComment = false;
        this._showCommentForm = false;
        this._showComments = false;
        this._widgets = [];

        route
            .url
            .pipe(
                takeUntil(this._destroyed)
            )
            .subscribe(urlSegments => {
                let pageUrl = urlSegments.join("/");
                pageUrl = encodeURIComponent(pageUrl);
                this.getPage(pageUrl);

                // Scroll to the top (in case of navigating from bottom menu links).
                window.scroll(0, 0);
            });
    }

    public ngOnDestroy(): void {
        this._destroyed.next(null);
        this._destroyed.complete();
    }

    public get comments(): IComment[] {
        return this._comments;
    }

    public get failureAddComment(): boolean {
        return this._failureAddComment;
    }

    public get failureAddCommentMessage(): string {
        return "Could not save comment.";
    }

    public get failureComments(): boolean {
        return this._failureComments;
    }

    public get failureCommentsMessage(): string {
        return "Could not get comments.";
    }

    public get failureFavorite(): boolean {
        return this._failureFavorite;
    }

    public get failureLike(): boolean {
        return this._failureLike;
    }

    public get failurePage(): boolean {
        return this._failurePage;
    }

    public get failurePageMessage(): string {
        return "Could not get page.";
    }

    public get favoriteIconTooltip(): string {
        return `Click to ${this._favorite ? "remove from" : "add to"} favorites`;
    }

    public get likeCountTooltip(): string {
        const likeCount = this._page.likes && this._page.likes.length || 0;

        return `${likeCount} ${likeCount === 1 ? "person likes" : "people like"} this page`;
    }

    public get likeIconTooltip(): string {
        return `Click to ${this._like ? "remove from" : "add to"} liked pages`;
    }

    public get isFavorite(): boolean {
        return !!this._favorite;
    }

    public get isLiked(): boolean {
        return !!this._like;
    }

    public get loadingComments(): boolean {
        return this._loadingComments;
    }

    public get loadingFavorite(): boolean {
        return this._loadingFavorite;
    }

    public get loadingLike(): boolean {
        return this._loadingLike;
    }

    public get loadingPage(): boolean {
        return this._loadingPage;
    }

    public get mailtoLink(): string {
        let mailto = "mailto:someone@terminix.com";
        mailto += "?subject=Check this out on Terminix Connect";
        mailto += `&body=${window.location.href}`;

        return mailto;
    }

    public get newComment(): IComment {
        return this._newComment;
    }

    public get page(): IPage {
        return this._page;
    }

    public get savingComment(): boolean {
        return this._savingComment;
    }

    public get showCommentForm(): boolean {
        return this._showCommentForm;
    }

    public get showComments(): boolean {
        return this._showComments;
    }

    public get showCommentsLabel(): string {
        return `${this._showComments ? "Hide" : "Show"} ${this._page.comments.length} comments`;
    }

    public get widgets(): IWidgetbase[] {
        return this._widgets;
    }

    public addComment(): void {
        if (!this._newComment.text) {
            this._notificationService.warn("Text is required to add a comment.");
            // TODO: angular2-notifications does not support Angular 8 right now.
            // Remove this when it does.
            UIkit.notification("Text is required to add a comment.", { status: "warning" });

            return;
        }

        this._failureAddComment = false;
        this._savingComment = true;

        this._apiService
            .find<IConnectuser>("connectusers", {
                _limit: 1,
                email: this._authState.userEmail
            })
            .pipe(
                flatMap(existingConnectUserResult => {
                    if (!(existingConnectUserResult && existingConnectUserResult.length)) {
                        const newConnectUser = <IConnectuser>{
                            email: this._authState.userEmail,
                            fullName: this._authState.userFullName,
                            shortName: this._authState.userFullName
                        };

                        return this._apiService.create<IConnectuser>("connectusers", newConnectUser);
                    }

                    return of(existingConnectUserResult[0]);
                }),
                flatMap(connectUser => {
                    if (!connectUser) {
                        return throwError("Could not create user information.");
                    }

                    const comment = <IComment>{
                        connectuser: {
                            _id: connectUser.id
                        },
                        page: {
                            _id: this._page.id
                        },
                        text: this._newComment.text
                    };

                    return this._apiService.create<IComment>("comments", comment);
                }),
                catchError(error => {
                    this._failureAddComment = true;
                    this._notificationService.error("Could not add comment", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<IComment>(null);
                }),
                finalize(() => {
                    this._savingComment = false;
                })
            )
            .subscribe(comment => {
                if (!comment) {
                    this._notificationService.error("Could not save comment.");
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification("Could not save comment.", { status: "danger" });

                    return;
                }

                this._notificationService.success("Comment saved.");
                // TODO: angular2-notifications does not support Angular 8 right now.
                // Remove this when it does.
                UIkit.notification("Comment saved.", { status: "success" });

                // Add to array of loaded comments for display.
                this._comments.push(comment);

                // Add to array of comments on _page to enable display if no comments previously existed.
                if (!this._page.comments) {
                    this._page.comments = [];
                }

                this._page.comments.push(comment);

                // In case there were initially no comments to show, set showComments to true.
                this._showComments = true;

                // Reset and hide comment form.
                this.cancelAddComment();
            });
    }

    public cancelAddComment(): void {
        this._showCommentForm = false;
        this._newComment = <IComment>{};
    }

    public getLayoutCss(layoutJson: ILayoutJson): object {
        const isMobile = window.innerWidth <= this._mobileWidth;
        const layout = layoutCss(layoutJson, isMobile);

        return layout;
    }

    public isAccordion(widget: IWidgetbase): boolean {
        return !!(widget as IAccordion).accordionitems;
    }

    public isImageGalleryWidget(widget: IWidgetbase): boolean {
        return widget instanceof ImageGalleryWidget;
    }

    public isImageWidget(widget: IWidgetbase): boolean {
        return widget instanceof ImageWidget;
    }

    public isJsonFormWidget(widget: IWidgetbase): boolean {
        return widget instanceof JsonFormWidget;
    }

    public isLegacyHtmlWidget(widget: IWidgetbase): boolean {
        return widget instanceof LegacyHtmlWidget;
    }

    public isRichTextWidget(widget: IWidgetbase): boolean {
        return widget instanceof RichTextWidget;
    }

    public isVideoWidget(widget: IWidgetbase): boolean {
        return widget instanceof Video;
    }

    public toggleFavorite(): void {
        if (this._favorite) {
            this.removeFavorite();
        }
        else {
            this.addFavorite();
        }
    }

    public toggleLike(): void {
        if (this._like) {
            this.removeLike();
        }
        else {
            this.addLike();
        }
    }

    public toggleShowCommentForm(): void {
        this._showCommentForm = !this._showCommentForm;
    }

    public toggleShowComments(): void {
        // If no comments have been loaded, get comments for the first time.
        if (!this._comments.length) {
            this.getComments();

            return;
        }

        this._showComments = !this._showComments;
    }

    private addFavorite(): void {
        this._loadingFavorite = true;

        this._apiService
            .find<IConnectuser>("connectusers", {
                _limit: 1,
                email: this._authState.userEmail
            })
            .pipe(
                flatMap(existingConnectUserResult => {
                    if (!(existingConnectUserResult && existingConnectUserResult.length)) {
                        const newConnectUser = <IConnectuser>{
                            email: this._authState.userEmail,
                            fullName: this._authState.userFullName,
                            shortName: this._authState.userFullName
                        };

                        return this._apiService.create<IConnectuser>("connectusers", newConnectUser);
                    }

                    return of(existingConnectUserResult[0]);
                }),
                flatMap(connectUser => {
                    if (!connectUser) {
                        return throwError("Could not create user information.");
                    }

                    const favorite = <IFavorite>{
                        connectuser: {
                            _id: connectUser.id
                        },
                        page: {
                            _id: this._page._id
                        }
                    };

                    return this._apiService.create<IFavorite>("favorites", favorite);
                }),
                catchError(error => {
                    this._notificationService.error("Could not add favorite", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<IFavorite>(null);
                }),
                finalize(() => {
                    this._loadingFavorite = false;
                })
            )
            .subscribe(favorite => {
                if (favorite) {
                    this._favorite = favorite;
                }
            });
    }

    private addLike(): void {
        this._loadingLike = true;

        this._apiService
            .find<IConnectuser>("connectusers", {
                _limit: 1,
                email: this._authState.userEmail
            })
            .pipe(
                flatMap(existingConnectUserResult => {
                    if (!(existingConnectUserResult && existingConnectUserResult.length)) {
                        const newConnectUser = <IConnectuser>{
                            email: this._authState.userEmail,
                            fullName: this._authState.userFullName,
                            shortName: this._authState.userFullName
                        };

                        return this._apiService.create<IConnectuser>("connectusers", newConnectUser);
                    }

                    return of(existingConnectUserResult[0]);
                }),
                flatMap(connectUser => {
                    if (!connectUser) {
                        return throwError("Could not create user information.");
                    }

                    const like = <ILike>{
                        connectuser: {
                            _id: connectUser.id
                        },
                        page: {
                            _id: this._page._id
                        }
                    };

                    return this._apiService.create<ILike>("likes", like);
                }),
                catchError(error => {
                    this._notificationService.error("Could not add like", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<ILike>(null);
                }),
                finalize(() => {
                    this._loadingLike = false;
                })
            )
            .subscribe(like => {
                if (like) {
                    this._like = like;

                    // Add to array of comments on _page to enable display if no comments previously existed.
                    if (!this._page.likes) {
                        this._page.likes = [];
                    }

                    this._page.likes.push(like);
                }
            });
    }

    private checkFavorite(): void {
        this._favorite = null;
        this._failureFavorite = false;
        this._loadingFavorite = true;

        this._apiService
            .getMany<IFavorite>("favorites", {
                "connectuser.email": this._authState.userEmail,
                "page._id": this._page._id
            })
            .pipe(
                catchError(error => {
                    this._failureFavorite = true;
                    this._notificationService.error("Could not get favorite status", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<IFavorite[]>([]);
                }),
                finalize(() => {
                    this._loadingFavorite = false;
                })
            )
            .subscribe(favorites => {
                if (favorites && favorites.length) {
                    this._favorite = favorites[0];
                }
            });
    }

    private checkLike(): void {
        this._like = null;
        this._failureLike = false;
        this._loadingLike = true;

        this._apiService
            .getMany<ILike>("likes", {
                "connectuser.email": this._authState.userEmail,
                "page._id": this._page._id
            })
            .pipe(
                catchError(error => {
                    this._failureLike = true;
                    this._notificationService.error("Could not get like status", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of<ILike[]>([]);
                }),
                finalize(() => {
                    this._loadingLike = false;
                })
            )
            .subscribe(likes => {
                if (likes && likes.length) {
                    this._like = likes[0];
                }
            });
    }

    private getComments(): void {
        this._comments = [];
        this._failureComments = false;
        this._loadingComments = true;

        this._apiService
            .query<IComment>(commentsQuery(), pageCommentsQueryParams(this._page.id))
            .pipe(
                map(response => {
                    return response.data && response.data.comments || [];
                }),
                catchError(error => {
                    this._notificationService.error("Could not get comments", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failureComments = true;

                    return of<IComment[]>([]);
                }),
                finalize(() => {
                    this._loadingComments = false;
                })
            )
            .subscribe(comments => {
                this._comments = comments;

                if (this._comments.length) {
                    this._showComments = true;
                }
            });
    }

    private getPage(pageUrl: string): void {
        this._page = null;
        this._widgets = [];
        this._failurePage = false;
        this._loadingPage = true;

        // Try to get the page using the URL initially obtained from the route.
        this._apiService
            .getMany<IPage>("pages", { url: pageUrl })
            .pipe(
                flatMap(pages => {
                    if (!(pages && pages[0])) {
                        // Add a trailing slash to the URL if necessary and try again.
                        const encodedSlash = encodeURIComponent("/");

                        if (!pageUrl.endsWith(encodedSlash)) {
                            pageUrl += encodedSlash;

                            return this._apiService.getMany<IPage>("pages", { url: pageUrl });
                        }
                    }

                    return of(pages);
                }),
                flatMap(pages => {
                    if (pages && pages[0]) {
                        this._page = pages && pages[0] || null;

                        if (this._page) {
                            // Get the model layout.
                            return this._apiService.getModelLayout("pages", pages[0].id);
                        }
                    }

                    return of<IModelLayout>(null);
                }),
                flatMap(modelLayout => {
                    if (modelLayout) {
                        this._page.layoutJson = modelLayout.layoutJson;
                    }

                    if (!(this._page && this._page.accordions && this._page.accordions.length)) {
                        return of<void[]>(null);
                    }

                    // Set accordion items.
                    const accordionItemsGets = this._page.accordions.map(a => {
                        return this._apiService
                            .getMany<IAccordionitem>("accordionitems")
                            .pipe(
                                flatMap(accordionItems => {
                                    if (accordionItems) {
                                        a.accordionitems = accordionItems;
                                    }

                                    return of<void>(null);
                                })
                            );
                    });

                    return zip(...accordionItemsGets);
                }),
                catchError(error => {
                    this._notificationService.error("Could not get page", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });
                    this._failurePage = true;

                    return of<void[]>(null);
                }),
                finalize(() => {
                    this._loadingPage = false;
                })
            )
            .subscribe(() => {
                if (!this._page) {
                    return;
                }

                // Set up widgets.
                const widgets: IWidgetbase[] = [];

                if (this._page.imagegallerywidgets) {
                    const mapped = this._page.imagegallerywidgets.map(igw => {
                        const widget = new ImageGalleryWidget(igw);
                        const index = this._page.layoutJson && this._page.layoutJson.findIndex(lj => lj.i === widget._id) || -1;

                        if (index > -1) {
                            widget.layoutJson = this._page.layoutJson[index];
                        }

                        return widget;
                    });

                    widgets.push(...mapped);
                }

                if (this._page.imagewidgets) {
                    const mapped = this._page.imagewidgets.map(iw => {
                        const widget = new ImageWidget(iw);
                        const index = this._page.layoutJson && this._page.layoutJson.findIndex(lj => lj.i === widget._id) || -1;

                        if (index > -1) {
                            widget.layoutJson = this._page.layoutJson[index];
                        }

                        return widget;
                    });

                    widgets.push(...mapped);
                }

                if (this._page.jsonform) {
                    const widget = new JsonFormWidget(this._page.jsonform);
                    const index = this._page.layoutJson && this._page.layoutJson.findIndex(lj => lj.i === widget._id) || -1;

                    if (index > -1) {
                        widget.layoutJson = this._page.layoutJson[index];
                    }

                    widgets.push(widget);
                }

                if (this._page.legacyhtmlwidgets) {
                    const mapped = this._page.legacyhtmlwidgets.map(lhw => {
                        const widget = new LegacyHtmlWidget(lhw);
                        const index = this._page.layoutJson && this._page.layoutJson.findIndex(lj => lj.i === widget._id) || -1;

                        if (index > -1) {
                            widget.layoutJson = this._page.layoutJson[index];
                        }

                        return widget;
                    });
                    widgets.push(...mapped);
                }

                if (this._page.richtextwidgets) {
                    const mapped = this._page.richtextwidgets.map(rtw => {
                        const widget = new RichTextWidget(rtw);
                        const index = this._page.layoutJson && this._page.layoutJson.findIndex(lj => lj.i === widget._id) || -1;

                        if (index > -1) {
                            widget.layoutJson = this._page.layoutJson[index];
                        }

                        return widget;
                    });

                    widgets.push(...mapped);
                }

                if (this._page.videos) {
                    const mapped = this._page.videos.map(v => {
                        const widget = new Video(v);
                        const index = this._page.layoutJson && this._page.layoutJson.findIndex(lj => lj.i === widget._id) || -1;

                        if (index > -1) {
                            widget.layoutJson = this._page.layoutJson[index];
                        }

                        return widget;
                    });

                    widgets.push(...mapped);
                }

                if (!this._page.accordions) {
                    this._page.accordions = [];
                }

                this._page.accordions.forEach(a => {
                    a.layoutJson = this._page.layoutJson && this._page.layoutJson.find(lj => lj.i === a._id) || null;
                });

                widgets.push(...this._page.accordions);

                this._widgets = sortLayout(widgets);

                this.checkFavorite();
                this.checkLike();
            });
    }

    private removeFavorite(): void {
        this._loadingFavorite = true;

        this._apiService
            .delete("favorites", this._favorite.id)
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not remove favorite", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of(false);
                }),
                finalize(() => {
                    this._loadingFavorite = false;
                })
            )
            .subscribe(success => {
                if (!success) {
                    this._notificationService.error("Could not remove favorite.");
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification("Could not remove favorite.", { status: "danger" });

                    return;
                }

                this._favorite = null;
            });
    }

    private removeLike(): void {
        this._loadingLike = true;

        this._apiService
            .delete("likes", this._like.id)
            .pipe(
                catchError(error => {
                    this._notificationService.error("Could not remove like", error);
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification(error, { status: "danger" });

                    return of(false);
                }),
                finalize(() => {
                    this._loadingLike = false;
                })
            )
            .subscribe(success => {
                if (!success) {
                    this._notificationService.error("Could not remove like.");
                    // TODO: angular2-notifications does not support Angular 8 right now.
                    // Remove this when it does.
                    UIkit.notification("Could not remove like.", { status: "danger" });

                    return;
                }

                this._like = null;

                // While the page's array of likes should not be undefined,
                // perform a sanity check just in case.
                if (!this._page.likes) {
                    return;
                }

                // Remove like from page likes to decrement count.
                // Note that likes are not actually displayed, just the count,
                // so removing any like from the array will achieve that effect.
                this._page.likes.pop();
            });
    }
}
