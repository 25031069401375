import { ImageAlignment } from "../../types/ImageAlignment";
import { IImagewidget, IWidgetbase, IImagegallerywidget, IPage, IImagegallerywidgetitem } from "../api";
import { ILayoutJson } from "../api/layoutJson";

export class ImageGalleryWidget implements IWidgetbase {
    public _id: string;
    public imagegallerywidgetitems: IImagegallerywidgetitem[];
    public name: string;
    public page: IPage;
    public layoutJson: ILayoutJson;

    public constructor(imageGalleryWidget: IImagegallerywidget) {
        this._id = imageGalleryWidget._id;
        this.imagegallerywidgetitems = imageGalleryWidget.imagegallerywidgetitems;
        this.name = imageGalleryWidget.name;
        this.page = imageGalleryWidget.page;
    }
}
