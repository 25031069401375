import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class FormService<T, D> {
    // Loaded from content type.
    private readonly _formLoaded: Subject<T>;
    // Submitted as DTO.
    private readonly _formSubmitted: Subject<D>;

    public constructor() {
        this._formLoaded = new Subject<T>();
        this._formSubmitted = new Subject<D>();
    }

    public get formLoaded(): Subject<T> {
        return this._formLoaded;
    }

    public get formSubmitted(): Subject<D> {
        return this._formSubmitted;
    }

    public onFormLoaded(form: T): void {
        this._formLoaded.next(form);
    }

    public onFormSubmitted(formDto: D): void {
        this._formSubmitted.next(formDto);
    }

    public scrollToField(label: string): void {
        const field: JQuery = $(`label:contains(${label})`);
        if (field && field.offset()) {
            const scrollToYPos: number = field.offset().top;
            window.scroll(0, scrollToYPos);
        }
    }
}