import { HttpClient } from "@angular/common/http";
import { Component, Input, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormioComponent, FormioForm } from "angular-formio";
import { NotificationsService } from "angular2-notifications";
import { finalize } from "rxjs/operators";
import { IJsonform } from "../../../models/api/jsonform";
import { IJsonFormAttributeDto } from "../../../models/dto/interfaces/IJsonFormAttributeDto";
import { JsonFormDto } from "../../../models/dto/JsonFormDto";
import { ApiService } from "../../../services/ApiService";
import { FormService } from "../../../services/FormService";
import { ContentTypeComponent } from "../../../types/ContentTypeComponent";

@Component({
    providers: [FormService],
    selector: "app-json-form",
    styleUrls: ["./json-form.component.scss"],
    templateUrl: "./json-form.component.html"
})
export class JsonFormComponent {
    @ViewChild("jsonFormRef", { static: false })
    private readonly _jsonFormRef: FormioComponent;

    private readonly _apiService: ApiService;
    private readonly _formService: FormService<IJsonform, JsonFormDto>;
    private readonly _httpClient: HttpClient;
    private readonly _notificationsService: NotificationsService;
    private readonly _router: Router;

    private _buttonLabel: string;
    private _error: string;
    private _jsonForm: IJsonform;
    private _loading: boolean;
    private _submitted: boolean;
    private _submitting: boolean;

    public constructor(
        apiService: ApiService,
        formService: FormService<IJsonform, JsonFormDto>,
        httpClient: HttpClient,
        notificationsService: NotificationsService,
        router: Router
    ) {
        this._apiService = apiService;
        this._formService = formService;
        this._httpClient = httpClient;
        this._notificationsService = notificationsService;
        this._router = router;

        this._buttonLabel = "Submit";
        this._error = "";
        this._jsonForm = { id: "" } as IJsonform;
        this._loading = false;
        this._submitted = false;
        this._submitting = false;
    }

    public get buttonLabel(): string {
        return this._buttonLabel;
    }

    public get error(): string {
        return this._error;
    }

    public get formFooter(): string {
        return this._jsonForm && this._jsonForm.formFooter || "";
    }

    public get formHeader(): string {
        return this._jsonForm && this._jsonForm.formHeader || "";
    }

    public get formJson(): string {
        return this._jsonForm && this._jsonForm.formJson || "";
    }

    public get jsonForm(): IJsonform {
        return this._jsonForm;
    }

    @Input()
    public set jsonForm(value: IJsonform) {
        if (value && value.id && value.formJson) {
            this._jsonForm = value;
        }
    }

    @Input()
    public set jsonFormId(value: string) {
        if (value) {
            this.loadForm(value);
        }
    }

    public get loading(): boolean {
        return this._loading;
    }

    public get submitted(): boolean {
        return this._submitted;
    }

    public get submitting(): boolean {
        return this._submitting;
    }

    public loadForm(jsonFormId: string): void {
        const component: ContentTypeComponent = "jsonforms";
        this._loading = true;
        this._apiService.findOne<IJsonform>(component, jsonFormId)
            .pipe(
                finalize(() => {
                    this._loading = false;
                })
            )
            .subscribe((form: IJsonform) => {
                if (form) {
                    this._jsonForm = form;
                }
                else {
                    this._notificationsService.error("Error getting form!");
                }
            }, () => {
                this._notificationsService.error("Error getting form!");
            });
    }

    public onFormLoaded(form: FormioForm): void {
        this._formService.onFormLoaded(this._jsonForm);
    }

    public onSubmit(): void {
        this._error = "";
        this._submitted = false;
        this._submitting = true;
        this._buttonLabel = "Submitting";
        this._jsonFormRef.formio.executeSubmit()
            .then((submission: any) => {
                this.submitForm(submission);
            }, (formErrors: any) => {
                console.log("Form Errors", formErrors);
                this.submitFormFailure("See form errors.");

                if (formErrors.length > 0
                    && formErrors[0].component
                    && formErrors[0].component.label
                ) {
                    // Scroll to the first error.
                    const firstErrorLabel = formErrors[0].component.label;
                    this._formService.scrollToField(firstErrorLabel);
                }
            });
    }

    private getJsonFormAttributes(formData: any[]): IJsonFormAttributeDto[] {
        const attributes: IJsonFormAttributeDto[] = [];
        Object.keys(formData)
            .forEach(key => attributes.push({
                attributeName: key,
                attributeValue: formData[key]
            }));

        return attributes;
    }

    private submitForm(submission: any): void {
        const jsonFormDto: JsonFormDto = new JsonFormDto();

        // Get public IP.
        this._httpClient.get<{ ip: string }>("https://api.ipify.org?format=json")
            .pipe(
                finalize(() => {
                    const formAttributes: IJsonFormAttributeDto[] = this.getJsonFormAttributes(submission.data);
                    jsonFormDto.attributes = formAttributes;
                    jsonFormDto.emailTo = this._jsonForm.emailTo || "";
                    jsonFormDto.title = this._jsonForm.title || "";

                    this._apiService.post<JsonFormDto>("jsonforms/sendForm", jsonFormDto)
                        .pipe(
                            finalize(() => {
                                this._submitting = false;
                            })
                        )
                        .subscribe((result: any) => {
                            this._submitted = result.success;
                            if (result.success) {
                                this._formService.onFormSubmitted(jsonFormDto);
                            }
                            this.submitFormResult(result);
                        }, () => {
                            this.submitFormFailure();
                        });
                })
            )
            .subscribe((result: { ip: string }) => {
                jsonFormDto.sourceIP = result.ip || "";
            }, () => {
                console.log("Error submitting form!");
            });
    }

    private submitFormFailure(error: string = ""): void {
        this._buttonLabel = "Submit";
        this._error = error || "Error submitting form!";
        this._submitting = false;
        this._notificationsService.error("Error", this._error);
    }

    private submitFormResult(result: any): void {
        if (result.success) {
            const successResult: string = "Submitted";
            this._buttonLabel = successResult;
            this._error = "";
            if (this._jsonForm.redirectUrl) {
                // Display notification after redirect if given a URL.
                this._router.navigateByUrl(this._jsonForm.redirectUrl)
                    .then((success: boolean) => {
                        if (success) {
                            this._notificationsService.success(successResult);
                        }
                    });
            }
            else {
                this._notificationsService.success(successResult);
            }
        }
        else {
            this.submitFormFailure(result.message);
        }
    }
}
