import { Component, Input } from "@angular/core";
import { splitRoute } from "../../../helpers/data.helpers";
import { IMainmenuitem, IMainmenusubmenuitem } from "../../../models/api";

@Component({
    selector: "app-menu-main-bottom",
    styleUrls: ["./menu-main-bottom.component.scss"],
    templateUrl: "./menu-main-bottom.component.html"
})
export class MenuMainBottomComponent {
    private _mainMenuItems: IMainmenuitem[];

    public constructor() {

        this._mainMenuItems = [];
    }

    public get mainMenuItems(): IMainmenuitem[] {
        return this._mainMenuItems;
    }

    @Input()
    public set mainMenuItems(value: IMainmenuitem[]) {
        if (value) {
            this._mainMenuItems = value;
        }
        else {
            this._mainMenuItems = [];
        }
    }

    public getMenuItemRouterLink(menuItem: IMainmenuitem): string[] {
        return splitRoute(menuItem.page.url);
    }

    public getSubMenuItemRouterLink(subMenuItem: IMainmenusubmenuitem): string[] {
        return splitRoute(subMenuItem.page.url);
    }
}
