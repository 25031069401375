export const mainmenuitemsQuery = () => `
    query {
        mainmenuitems {
            _id,
            externalUrl,
            mainmenusubmenuitems {
                _id,
                externalUrl,
                name,
                order,
                page {
                    _id,
                    title,
                    url
                }
            },
            name,
            order,
            page {
                _id,
                title,
                url
            }
        }
    }
`;
