import { IJsonform, IWidgetbase } from "../api";
import { ILayoutJson } from "../api/layoutJson";

export class JsonFormWidget implements IWidgetbase {
    public _id: string;
    public emailTo: string;
    public formFooter: string;
    public formHeader: string;
    public formJson: string;
    public id: string;
    public layoutJson: ILayoutJson;
    public redirectUrl: string;
    public title: string;

    public constructor(jsonFormWidget: IJsonform) {
        this._id = jsonFormWidget._id;
        this.emailTo = jsonFormWidget.emailTo;
        this.formFooter = jsonFormWidget.formFooter;
        this.formHeader = jsonFormWidget.formHeader;
        this.formJson = jsonFormWidget.formJson;
        this.id = jsonFormWidget.id;
        this.redirectUrl = jsonFormWidget.redirectUrl;
        this.title = jsonFormWidget.title;
    }
}
