import { Component, OnInit, Input } from "@angular/core";
import { IVideo } from "../../models/api/video";
import { CommonUtils } from "../common/commonUtils";
import { DomSanitizer } from "@angular/platform-browser";
import { Video } from "../../models/widgets/Video";

@Component({
    selector: "app-video",
    styleUrls: ["./video.component.scss"],
    templateUrl: "./video.component.html"
})
export class VideoComponent {
    private readonly _sanitizer: DomSanitizer;

    private _video: Video;

    public constructor(
        sanitizer: DomSanitizer
    ) {
        this._sanitizer = sanitizer;

        this._video = null;
    }

    public get video(): Video {
        return this._video;
    }

    @Input()
    public set video(v: Video) {
        this._video = v;
        if (!this._video.safeUrl) {
            if (!this._video.media) {
                if (this._video.url.length > 1) {
                    const videoType: string = this.determineVideoType(this._video.url);
                    let videoLink: string = this._video.url;

                    if (videoType === "youtube") {
                        const regExp: RegExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
                        const videoId: string[] = this._video.url.match(regExp);

                        if (videoId.length > 1) {
                            videoLink = `https://www.youtube.com/embed/${videoId[1]}`;
                        }
                        else {
                            return;
                        }
                    }
                    else if (videoType === "vimeo") {
                        const id: string = this.extractVideoIdFromVimeoUrl(this._video.url);
                        videoLink = `https://player.vimeo.com/video/${id}`;
                    }

                    this._video.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(videoLink);
                }
            }
            else {
                this._video.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
                    CommonUtils.getImageUrl(this._video.media)
                );
            }
        }
    }

    public get posterUrl(): string {
        return this._video
            && this._video.poster
            && CommonUtils.getImageUrl(this._video.poster)
            || "";
    }

    public get isMp4(): boolean {
        return this._video
            && this._video.url
            && this._video.url.toLowerCase()
                .endsWith(".mp4")
            || false;
    }

    private determineVideoType(url: string): string {
        const youtubeRegExp = /.*youtube.*/;
        const youtubeEmbeddedRegExp = /.*youtu.be.*/;
        const vimeoRegExp = /.*vimeo.*/;

        if (url.match(youtubeRegExp) || url.match(youtubeEmbeddedRegExp)) {
            return "youtube";
        }
        else if (url.match(vimeoRegExp)) {
            return "vimeo";
        }
        else {
            return "";
        }
    }

    private extractVideoIdFromVimeoUrl(url: string): string {
        const stepOne = url.split("?")[0];
        const stepTwo = stepOne.split("/");
        const videoId = stepTwo[stepTwo.length - 1];

        return videoId;
    }

}
