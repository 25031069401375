import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { splitRoute } from "../../../helpers/data.helpers";
import { defaultLogoSvg } from "../../../helpers/view.helpers";
import { IMainmenuitem, IMainmenusubmenuitem } from "../../../models/api";
import { ViewTypeState } from "../../../state/ViewTypeState";

const DROPDOWN_COLUMN_BREAK_THRESHOLD = 15;

@Component({
    selector: "app-menu-main-top",
    styleUrls: ["./menu-main-top.component.scss"],
    templateUrl: "./menu-main-top.component.html"
})
export class MenuMainTopComponent {
    @ViewChild("menuMainOffcanvas", { static: false })
    private readonly _menuMainOffcanvas: ElementRef;

    private _isTop: boolean;
    private _logoSvg: string;
    private _mainMenuItems: IMainmenuitem[];
    private _navbarClass: string;
    private _navbarItemClass: string;
    private _showSubMenuItems: boolean;

    public constructor(
        viewTypeState: ViewTypeState
    ) {
        this._isTop = false;
        this._logoSvg = defaultLogoSvg;
        this._mainMenuItems = [];
        this._navbarClass = "";
        this._navbarItemClass = "";
        this._showSubMenuItems = true;

        viewTypeState
            .viewTypeActions
            .subscribe(vt => {
                if (vt) {
                    this._logoSvg = vt.logoSvg || defaultLogoSvg;
                }
            });
    }

    public get dropdownPositionAttribute(): string {
        const pos =
            this._isTop
                ? "bottom-right"
                : "top-right";

        return `{ pos: '${pos}' }`;
    }

    public get isTop(): boolean {
        return this._isTop;
    }

    @Input()
    public set isTop(value: boolean) {
        this._isTop = value;
    }

    public get logoSvg(): string {
        return this._logoSvg;
    }

    public get mainMenuItems(): IMainmenuitem[] {
        return this._mainMenuItems;
    }

    @Input()
    public set mainMenuItems(value: IMainmenuitem[]) {
        if (value) {
            this._mainMenuItems = value;
        }
        else {
            this._mainMenuItems = [];
        }
    }

    public get navbarClass(): string {
        return this._navbarClass;
    }

    @Input()
    public set navbarClass(v: string) {
        this._navbarClass = v;
    }

    public get navbarItemClass(): string {
        return this._navbarItemClass;
    }

    @Input()
    public set navbarItemClass(v: string) {
        this._navbarItemClass = v;
    }

    public get showSubMenuItems(): boolean {
        return this._showSubMenuItems;
    }

    @Input()
    public set showSubMenuItems(v: boolean) {
        this._showSubMenuItems = v;
    }

    public breakDropdownColumns(menuItem: IMainmenuitem): boolean {
        if (!menuItem.mainmenusubmenuitems) {
            return false;
        }

        return menuItem.mainmenusubmenuitems.length > DROPDOWN_COLUMN_BREAK_THRESHOLD;
    }

    public getMenuItemRouterLink(menuItem: IMainmenuitem): string[] {
        return splitRoute(menuItem.page.url);
    }

    public getSubMenuItemRouterLink(subMenuItem: IMainmenusubmenuitem): string[] {
        return splitRoute(subMenuItem.page.url);
    }

    public showOffcanvas(): void {
        if (this._isTop) {
            UIkit.offcanvas(this._menuMainOffcanvas.nativeElement)
                .show();
        }
    }
}
