import { Component, Input } from "@angular/core";
import { IAccordion } from "../../../models/api";

@Component({
    selector: "app-accordion",
    styleUrls: ["./accordion.component.scss"],
    templateUrl: "./accordion.component.html"
})
export class AccordionComponent {
    private _accordion: IAccordion;

    public constructor() {
        this._accordion = null;
    }

    public get accordion(): IAccordion {
        return this._accordion;
    }

    @Input()
    public set accordion(value: IAccordion) {
        this._accordion = value;
    }
}
