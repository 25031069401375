import { environment } from "../../../environments/environment";

export class CommonUtils {
    public static getImageUrl(imageBlob: Blob): string {
        let url: string = "";
        if (imageBlob) {
            const imageData = imageBlob as any;
            if (imageData.url) {
                if ((imageData.url as string).startsWith("https")) {
                    url = imageData.url as string;
                }
                else {
                    url = `${environment.apiBaseUrl}/${imageData.url}`;
                }
            }
        }

        return url;
    }
}