import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { flatMap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { IModelLayout } from "../models/api/modelLayout";
import { ContentTypeComponent } from "../types/ContentTypeComponent";
import { QueryParams } from "../types/QueryParams";
import { QueryResponse } from "../types/QueryResponse";

@Injectable()
export class ApiService {
    private readonly _httpClient: HttpClient;

    public constructor(
        httpClient: HttpClient
    ) {
        this._httpClient = httpClient;
    }

    public count(component: ContentTypeComponent, params?: QueryParams): Observable<number> {
        let route = this.buildRoute(`${component}/count`);

        if (params) {
            route += this.buildQueryString(params);
        }

        return this._httpClient.get<number>(route);
    }

    public create<T>(component: ContentTypeComponent, body: T): Observable<T> {
        const route = this.buildRoute(component);

        return this._httpClient.post<T>(route, body);
    }

    public delete(component: ContentTypeComponent, id: string): Observable<boolean> {
        const route = this.buildRoute(component, id);

        return this._httpClient
            .delete(route)
            .pipe(
                map(result => {
                    return !!result;
                })
            );
    }

    public getById<T>(component: ContentTypeComponent, id: string, params?: QueryParams): Observable<T> {
        let route = this.buildRoute(component, id);

        if (params) {
            route += this.buildQueryString(params);
        }

        return this._httpClient.get<T>(route);
    }

    public getMany<T>(component: ContentTypeComponent, params?: QueryParams): Observable<T[]> {
        let route = this.buildRoute(component);

        if (params) {
            route += this.buildQueryString(params);
        }

        return this._httpClient.get<T[]>(route);
    }

    public getModelLayout(modelType: ContentTypeComponent, modelId: string): Observable<IModelLayout> {
        const route = `${environment.apiBaseUrl}/layout-editor/${modelType}/${modelId}/layouts`;

        return this._httpClient.get<IModelLayout>(route);
    }

    public find<T>(component: ContentTypeComponent, params?: QueryParams): Observable<T[]> {
        let route = this.buildRoute(component);

        if (params) {
            route += this.buildQueryString(params);
        }

        return this._httpClient.get<T[]>(route);
    }

    public findOne<T>(component: ContentTypeComponent, id: string, params?: QueryParams): Observable<T> {
        let route = this.buildRoute(component, id);

        if (params) {
            route += this.buildQueryString(params);
        }

        return this._httpClient.get<T>(route);
    }

    public post<T>(apiEndpoint: string, body: T): Observable<T> {
        const route = this.buildRoute(apiEndpoint);

        return this._httpClient.post<T>(route, body);
    }

    public query<T>(query: string, params?: QueryParams): Observable<QueryResponse<T>> {
        const route = this.buildRoute("graphql");
        query = query.replace(/\s/g, "");

        const queryParams: QueryParams = {
            query
        };

        if (params) {
            queryParams.variables = {
                where: params
            };
        }

        return this._httpClient.post<QueryResponse<T>>(route, queryParams)
            .pipe(
                flatMap(response => {
                    if (response.errors && response.errors[0]) {
                        return throwError(response.errors[0].message);
                    }

                    return of(response);
                })
            );
    }

    private buildQueryString(params: QueryParams): string {
        const queryStringParams: string[] = [];

        for (const property in params) {
            const value = params[property];

            if (value instanceof Array) {
                for (const v of value) {
                    queryStringParams.push(`${property}=${v}`);
                }
            }
            else {
                queryStringParams.push(`${property}=${value}`);
            }
        }

        const queryString = queryStringParams.join("&");

        return `?${queryString}`;
    }

    private buildRoute(route: string, id?: string): string {
        let fullRoute = `${environment.apiBaseUrl}/${route}`;

        if (id) {
            fullRoute += `/${id}`;
        }

        return fullRoute;
    }
}
